import { makeObservable, observable, action } from "mobx";
import * as teamService from "../services/teamService";
import * as companyService from "../services/companyService";
import { getCoacheeSprints } from "../services/coachService";

import moment from "moment";

export class TeamStore {
  userTeams = null;
  allTeams = null;
  selectedTeam = null;
  selectedObjective = null;
  objectiveDialogOpen = false;
  teamsLoading = false;
  teamDataLoading = false;
  isChartMode = false;
  skillsUnderDevelopment = [];
  startDate = new Date();
  endDate = moment().add(12, "month").endOf("month").toDate();
  teamObjectiveHistory = [];
  teamObjectiveKrHistory = [];
  allTeamsDetails = null;
  objectiveLoading = false;
  allTeamsObjectives = null;

  getTeams = async () => {
    this.teamsLoading = true;
    let data = await this.getTeamsOnly();
    data.teams.map((team) => {
      if (data.userTeams) {
        if (
          data.userTeams.filter((userTeam) => userTeam.id === team.id).length >
          0
        ) {
          !this.selectedTeam && this.getTeam(team.id);
        }
      }
    });
    this.teamsLoading = false;
  };

  getTeamsOnly = async () => {
    let data = await teamService.getAllTeams();
    this.allTeams = data.teams;
    this.userTeams = data.userTeams;
    return data;
  };

  getTeam = async (id) => {
    this.teamDataLoading = true;
    if (id) {
      let data = await teamService.getTeam(id);
      if (data.getTeam) {
        let team = data.getTeam;
        this.selectedTeam = team;
        const res = await teamService.getTeamSlackAnswerData(id);
        team.teamSlackAnswerData = res.teamSlackAnswerData;
        if (team.teamLead) {
          let teamLeadSprintData = await getCoacheeSprints(team.teamLead.id);
          const teamLeadActiveSprint =
            teamLeadSprintData.userDevelopmentSprints.find(
              (sprint) => sprint.status === "ACTIVE"
            );
          team.teamLead.activeSprint = teamLeadActiveSprint;
        }
        if (team.members.length > 0) {
          team.members.map(async (member, i) => {
            let sprintData = await getCoacheeSprints(member.id);
            const activeSprint = sprintData.userDevelopmentSprints.find(
              (sprint) => sprint.status === "ACTIVE"
            );

            let goalData = await teamService.getUserGoals(member.id);
            team.members[i].goals = goalData.userGoalsForSearch;
            team.members[i].activeSprint = activeSprint;

            if (data.teamSkills) {
              team.skills = data.teamSkills;
            }
            if (this.selectedObjective) {
              this.selectedObjective = team.objectives.find(
                (objective) => objective.id === this.selectedObjective.id
              );
            }
          });
        } else {
          if (this.selectedObjective) {
            this.selectedObjective = team.objectives.find(
              (objective) => objective.id === this.selectedObjective.id
            );
          }
        }
      }
    } else {
      this.selectedTeam = null;
    }
    this.teamDataLoading = false;
  };

  getAllTeamsDetails = async () => {
    let data = await teamService.getAllTeamsDetails();
    this.allTeamsDetails = data.teams;
  };

  getTeamObjectiveHistory = async (teamObjectiveId) => {
    const data = await teamService.getTeamObjectiveHistory(teamObjectiveId);
    this.teamObjectiveHistory = data.teamObjectiveHistory;
  };

  getTeamObjectiveKrHistory = async (teamObjectiveKrId) => {
    const data = await teamService.getTeamObjectiveKrHistory(teamObjectiveKrId);
    this.teamObjectiveKrHistory = data.teamObjectiveKeyResultHistory;
  };

  createTeamObjective = async (data) => {
    this.teamsLoading = true;
    const result = await teamService.createTeamObjective(data);
    await this.getTeam(this.selectedTeam.id);

    this.setObjectiveDialogState(true);
    this.setActiveObjectiveById(result.createTeamObjective.teamObjective.id);

    this.teamsLoading = false;
    return result.createTeamObjective.teamObjective;
  };

  createTeamObjectiveFromKr = async (data) => {
    const result = await teamService.createTeamObjective(data);
    return result.createTeamObjective.teamObjective;
  };

  updateTeamObjective = async (data, teamObjectiveId) => {
    this.teamsLoading = true;
    await teamService.updateTeamObjective(data, teamObjectiveId);
    await this.getTeam(this.selectedTeam.id);
    this.teamsLoading = false;
  };

  deleteTeamObjective = async (objectiveId) => {
    this.teamsLoading = true;
    await teamService.deleteTeamObjective(objectiveId);
    await this.getTeam(this.selectedTeam.id);
    this.teamsLoading = false;
  };

  setActiveObjective = (objective) => {
    this.selectedObjective = objective;
  };

  setActiveObjectiveById = async (objectiveId) => {
    await this.getTeam(this.selectedTeam.id);
    this.selectedObjective = this.selectedTeam.objectives.find((objective) => {
      return objective.id === objectiveId;
    });
  };

  getAllTeamsObjectives = async () => {
    let data = await teamService.getAllObjectives();
    this.allTeamsObjectives = data.teamObjectives;
  };

  setActiveObjectiveByIdFromKr = (objectiveId) => {
    this.selectedObjective = this.allTeamsObjectives.find((objective) => {
      return objective.id === objectiveId;
    });
  };

  setObjectiveDialogState = (state) => {
    this.objectiveDialogOpen = state;
  };

  setIsChartMode = (mode) => {
    this.isChartMode = mode;
  };

  createTeamObjectiveKeyResult = async (data) => {
    this.teamsLoading = true;
    await teamService.createTeamObjectiveKeyResult(data);
    await this.getTeam(this.selectedTeam.id);
    this.teamsLoading = false;
  };

  createTeamObjectiveKeyResultFromKr = async (data) => {
    await teamService.createTeamObjectiveKeyResult(data);
    this.setActiveObjectiveByIdFromKr(data.teamObjectiveId);
  };

  updateTeamObjectiveKeyResult = async (data, teamObjectiveKeyResultId) => {
    this.teamsLoading = true;
    await teamService.updateTeamObjectiveKeyResult(
      data,
      teamObjectiveKeyResultId
    );
    await this.getTeam(this.selectedTeam.id);
    await this.getTeamObjectiveKrHistory(teamObjectiveKeyResultId);
    this.teamsLoading = false;
  };

  deleteTeamObjectiveKeyResult = async (teamObjectiveKeyResultId) => {
    this.teamsLoading = true;
    await teamService.deleteTeamObjectiveKeyResult(teamObjectiveKeyResultId);
    await this.getTeam(this.selectedTeam.id);
    this.setObjectiveDialogState(true);
    this.teamsLoading = false;
  };

  getSkillsUnderDevelopment = async () => {
    this.teamLoading = true;
    const data = await companyService.getSkillsUnderDevelopment();
    if (data.skillsUnderDevelopment.length > 0) {
      const skills = data.skillsUnderDevelopment.filter(
        (skill) => skill.teamObjectives.length > 0
      );
      this.skillsUnderDevelopment = skills;
    }
  };

  setStartDate = (state) => {
    this.startDate = state;
  };

  setEndDate = (state) => {
    this.endDate = state;
  };

  constructor() {
    makeObservable(this, {
      userTeams: observable,
      allTeams: observable,
      selectedTeam: observable,
      teamsLoading: observable,
      selectedObjective: observable,
      objectiveDialogOpen: observable,
      isChartMode: observable,
      endDate: observable,
      startDate: observable,
      teamObjectiveHistory: observable,
      teamObjectiveKrHistory: observable,
      allTeamsDetails: observable,
      teamDataLoading: observable,
      objectiveLoading: observable,
      allTeamsObjectives: observable,
      setActiveObjective: action,
      setObjectiveDialogState: action,
      createTeamObjective: action,
      createTeamObjectiveFromKr: action,
      updateTeamObjective: action,
      deleteTeamObjective: action,
      getTeams: action,
      getTeam: action,
      setIsChartMode: action,
      createTeamObjectiveKeyResult: action,
      updateTeamObjectiveKeyResult: action,
      deleteTeamObjectiveKeyResult: action,
      setStartDate: action,
      setEndDate: action,
      setActiveObjectiveById: action,
      setActiveObjectiveByIdFromKr: action,
      getTeamObjectiveHistory: action,
      getTeamObjectiveKrHistory: action,
      getAllTeamsDetails: action,
      createTeamObjectiveKeyResultFromKr: action,
      getAllTeamsObjectives: action,
      getTeamsOnly: action,
    });
  }
}
