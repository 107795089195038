import { makeObservable, observable, action } from "mobx";
import * as userService from "../services/userService";

export class GeneralStore {
  sideMenuOpen = false;
  notificationMenuOpen = false;
  viewableUser = null;
  viewableUserOneOnOnes = null;
  notifications = [];
  sprintNotifications = [];
  coacheeQuestionnaireNotification = [];
  prevRoute = null;
  kRDialogOpen = false;
  selectedKR = null;
  sideMenuMinimized = false;
  goalDialogMode = false;
  libraryDialogMode = false;
  goalDialogOpen = false;
  libraryDialogOpen = false;
  scrollPosition = 0;
  evaluationNotifications = [];
  badgeContent = null;
  variant = "standard";

  toggleMenu = () => {
    this.sideMenuOpen = !this.sideMenuOpen;
  };

  toggleToMinimize = (state) => {
    this.sideMenuMinimized = state;
  };

  setNotificationState = (state) => {
    this.notificationMenuOpen = state;
  };

  toggleNotificationMenu = () => {
    this.notificationMenuOpen = !this.notificationMenuOpen;
  };

  getViewableUser = async (userId) => {
    let data = await userService.getExtProfile(userId);
    this.viewableUser = data.userProfileForSearch;
  };

  cleanViewableUser = async () => {
    this.viewableUser = null;
  };

  getViewableUserOneOnOnes = async () => {
    let data = await userService.getOneOnOnes(this.viewableUser.userId);
    this.viewableUserOneOnOnes = data.oneOnOnes;
  };

  createOneOnOne = async (forId, fromId, memo, checkpointId) => {
    let input;
    if (checkpointId) {
      input = {
        forId: forId,
        fromId: fromId,
        memo: memo,
        checkpointId: checkpointId,
      };
    } else {
      input = {
        forId: forId,
        fromId: fromId,
        memo: memo,
      };
    }
    await userService.createOneOnOne(input);
  };

  updateOneOnOne = async (forId, fromId, memo, oneOnOneId, checkpointId) => {
    let input;
    if (checkpointId) {
      input = {
        forId: forId,
        fromId: fromId,
        memo: memo,
        checkpointId: checkpointId,
      };
    } else {
      input = {
        forId: forId,
        fromId: fromId,
        memo: memo,
      };
    }
    await userService.updateOneOnOne(input, oneOnOneId);
  };

  setNotifications = (notifications) => {
    this.notifications = notifications;
  };

  setSprintNotifications = (notifications) => {
    this.sprintNotifications = notifications;
  };
  setCoacheeQuestionnaireNotification = (notifications) => {
    this.coacheeQuestionnaireNotification = notifications;
  };

  setKRDialogOpen = (state) => {
    this.kRDialogOpen = state;
  };

  setSelectedKr = (kr) => {
    this.selectedKR = kr;
  };

  resetStore = () => {
    this.sideMenuOpen = false;
    this.notificationMenuOpen = false;
    this.viewableUser = null;
    this.viewableUserOneOnOnes = null;
    this.notifications = [];
    this.sprintNotifications = [];
  };
  setPrevRoute = (route) => {
    this.prevRoute = route;
  };

  setGoalDialogMode = (mode) => {
    this.goalDialogMode = mode;
  };

  setLibraryDialogMode = (mode) => {
    this.libraryDialogMode = mode;
  };

  setGoalDialogOpen = (state) => {
    this.goalDialogOpen = state;
  };

  setLibraryDialogOpen = (state) => {
    this.libraryDialogOpen = state;
  };

  setScrollPosition = (position) => {
    this.scrollPosition = position;
  };

  setEvaluationNotifications = (notifications) => {
    this.evaluationNotifications = notifications;
  };

  setBadgeContent = (content) => {
    this.badgeContent = content;
  };

  setVariant = (variant) => {
    this.variant = variant;
  };

  constructor() {
    makeObservable(this, {
      toggleMenu: action,
      getViewableUser: action,
      getViewableUserOneOnOnes: action,
      cleanViewableUser: action,
      createOneOnOne: action,
      updateOneOnOne: action,
      toggleNotificationMenu: action,
      setNotifications: action,
      setNotificationState: action,
      setSprintNotifications: action,
      resetStore: action,
      setPrevRoute: action,
      setCoacheeQuestionnaireNotification: action,
      setKRDialogOpen: action,
      setSelectedKr: action,
      toggleToMinimize: action,
      setGoalDialogMode: action,
      setLibraryDialogMode: action,
      setGoalDialogOpen: action,
      setLibraryDialogOpen: action,
      setScrollPosition: action,
      setEvaluationNotifications: action,
      setBadgeContent: action,
      setVariant: action,
      badgeContent: observable,
      variant: observable,
      sideMenuOpen: observable,
      notificationMenuOpen: observable,
      viewableUser: observable,
      viewableUserOneOnOnes: observable,
      notifications: observable,
      sprintNotifications: observable,
      prevRoute: observable,
      coacheeQuestionnaireNotification: observable,
      kRDialogOpen: observable,
      selectedKR: observable,
      sideMenuMinimized: observable,
      goalDialogMode: observable,
      libraryDialogMode: observable,
      goalDialogOpen: observable,
      libraryDialogOpen: observable,
      scrollPosition: observable,
      evaluationNotifications: observable,
    });
  }
}
