import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  concat,
} from "@apollo/client";
import absintheSocketLink from "./absinthe-socket-link";
import { createLink } from "apollo-absinthe-upload-link";
import { setContext } from "@apollo/client/link/context";
import { hasSubscription } from "@jumpn/utils-graphql";
import { onError } from "@apollo/client/link/error";

const authLink = setContext((_, { headers }) => {
  const token = window.localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const errorLink = onError(({ response, graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message }) => {
      if (message.includes("Unauthorized")) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("currentUser");
        document.location.href = "/";
      }
    });
  if (networkError)
    console.log(`[Network error]: ${networkError}, can't connect to server`);
});

const api_url = `${process.env.REACT_APP_API_URL || ""}/api`;

const httpLink = ApolloLink.from([
  authLink,
  errorLink,
  cleanTypeName,
  createHttpLink({
    uri: api_url,
  }),
]);

const imageUploadLink = ApolloLink.from([
  authLink,
  errorLink,
  createLink({
    uri: api_url,
  }),
]);

const link = new ApolloLink.split(
  (operation) => hasSubscription(operation.query),
  concat(authLink, absintheSocketLink),
  imageUploadLink,
  httpLink
);

export default new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});
