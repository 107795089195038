import { makeObservable, observable, action } from "mobx";
import * as companyService from "../services/companyService";
import moment from "moment";

export class CompanyStore {
  companyObjectives = [];
  companyLoading = false;
  selectedObjective = null;
  objectiveDialogOpen = false;
  startDate = new Date();
  skillsUnderDevelopment = [];
  oneOnOnes = [];
  endDate = moment().add(12, "month").endOf("month").toDate();
  companyUsersWithSkills = null;
  developmentSprintsData = null;
  companyLibraryData = null;
  companyObjectiveHistory = [];
  companyObjectiveKrHistory = [];
  companyUsers = [];
  employeeRoles = [];
  isTeamOkrFromKr = false;
  employeeCertificates = [];
  skillsTabIndex = 0;
  companyVisions = [];
  activeCompanyVision = null;
  companyStrategies = [];
  activeCompanyStrategy = null;
  companySlackAnswerData = null;
  objectivesLoading = false;

  getOneOnOnes = async () => {
    this.companyLoading = true;
    let currentYear = new Date().getFullYear();
    let data = await companyService.getOneOnOnes(currentYear);
    this.oneOnOnes = data.allOneOnOnes;
    this.companyLoading = false;
  };

  getCompanyObjectives = async (from, to) => {
    this.objectivesLoading = true;
    let data = await companyService.getCompanyObjectives(from, to);
    this.companyObjectives = data.companyObjectives;
    if (this.selectedObjective) {
      this.selectedObjective = data.companyObjectives.find(
        (objective) => objective.id === this.selectedObjective.id
      );
    }
    this.objectivesLoading = false;
  };

  getDevelopmentSprintsData = async (from, to) => {
    this.companyLoading = true;
    const data = await companyService.getDevelopmentSprintsData(from, to);
    this.developmentSprintsData = data.developmentSprintData;
    this.companyLoading = false;
  };

  getCompanyObjectiveHistory = async (companyObjectiveId) => {
    const data = await companyService.getCompanyObjectiveHistory(
      companyObjectiveId
    );
    this.companyObjectiveHistory = data.companyObjectiveHistory;
  };

  getCompanyObjectiveKrHistory = async (companyObjectiveKrId) => {
    const data = await companyService.getCompanyObjectiveKrHistory(
      companyObjectiveKrId
    );
    this.companyObjectiveKrHistory = data.companyObjectiveKeyResultHistory;
  };

  getCompanyUsers = async () => {
    let data = await companyService.getCompanyUsers();
    this.companyUsers = data.companyUsers;
  };

  getCompanySlackAnswerData = async () => {
    let data = await companyService.getCompanySlackAnswerData();
    this.companySlackAnswerData = data.companySlackAnswerData;
  };

  createCompanyObjective = async (data) => {
    this.companyLoading = true;
    const result = await companyService.createCompanyObjective(data);
    await this.getCompanyObjectives();
    this.setObjectiveDialogState(true);
    this.setActiveObjectiveById(
      result.createCompanyObjective.companyObjective.id
    );
    this.companyLoading = false;
    return result;
  };

  setActiveObjectiveById = (objectiveId) => {
    this.selectedObjective = this.companyObjectives.find(
      (objective) => objective.id === objectiveId
    );
  };

  getCompanyUsersWithSkills = async () => {
    this.companyLoading = true;
    let data = await companyService.getCompanyUsersWithSkills();
    this.companyUsersWithSkills = data.companyUsers;
    this.companyLoading = false;
  };

  updateCompanyObjective = async (data, companyObjectiveId) => {
    this.companyLoading = true;
    await companyService.updateCompanyObjective(data, companyObjectiveId);
    await this.getCompanyObjectives();
    this.companyLoading = false;
  };

  deleteCompanyObjective = async (objectiveId) => {
    this.companyLoading = true;
    await companyService.deleteCompanyObjective(objectiveId);
    await this.getCompanyObjectives();
    this.companyLoading = false;
  };

  createCompanyObjectiveKeyResult = async (data) => {
    this.companyLoading = true;
    await companyService.createCompanyObjectiveKeyResult(data);
    await this.getCompanyObjectives();
    this.companyLoading = false;
  };

  updateCompanyObjectiveKeyResult = async (
    data,
    companyObjectiveKeyResultId
  ) => {
    this.companyLoading = true;
    await companyService.updateCompanyObjectiveKeyResult(
      data,
      companyObjectiveKeyResultId
    );
    await this.getCompanyObjectives();
    await this.getCompanyObjectiveKrHistory(companyObjectiveKeyResultId);
    this.companyLoading = false;
  };

  deleteCompanyObjectiveKeyResult = async (companyObjectiveKeyResultId) => {
    this.companyLoading = true;
    await companyService.deleteCompanyObjectiveKeyResult(
      companyObjectiveKeyResultId
    );
    await this.getCompanyObjectives();
    this.companyLoading = false;
  };

  getSkillsUnderDevelopment = async () => {
    const data = await companyService.getSkillsUnderDevelopment();

    if (data.skillsUnderDevelopment.length > 0) {
      this.skillsUnderDevelopment = data.skillsUnderDevelopment;
    }
  };

  setActiveObjective = (objective) => {
    this.selectedObjective = objective;
  };

  setObjectiveDialogState = (state) => {
    this.objectiveDialogOpen = state;
  };

  setStartDate = (state) => {
    this.startDate = state;
  };

  setEndDate = (state) => {
    this.endDate = state;
  };

  setSkillTabIndex = (index) => {
    this.skillsTabIndex = index;
  };

  getEmployeeRoles = async () => {
    let data = await companyService.getEmployeeRoles();
    this.employeeRoles = data.employeeRoles;
  };

  setIsTeamOkrFromKr = (state) => {
    this.isTeamOkrFromKr = state;
  };

  getEmployeeCertificates = async () => {
    let data = await companyService.getEmployeeCertificates();
    this.employeeCertificates = data.certificates;
  };

  getCompanyLibraryData = async () => {
    let data = await companyService.getCompanyLibraryData();
    this.companyLibraryData = data.companyLibraryReporting[0];
  };

  getCompanyVisions = async () => {
    this.activeCompanyVision = null;
    const data = await companyService.getCompanyVisions();
    data.companyVisions.map((vision) => {
      if (vision.status === "ACTIVE") {
        this.activeCompanyVision = vision;
      }
    });
    this.companyVisions = data.companyVisions;
  };

  createCompanyVision = async (companyVision) => {
    await companyService.createCompanyVision(companyVision);
    await this.getCompanyVisions();
  };

  updateCompanyVision = async (companyVision, id) => {
    await companyService.updateCompanyVision(companyVision, id);
    await this.getCompanyVisions();
  };

  deleteCompanyVision = async (id) => {
    await companyService.deleteCompanyVision(id);
    await this.getCompanyVisions();
  };

  getCompanyStrategies = async () => {
    this.activeCompanyStrategy = null;
    const data = await companyService.getCompanyStrategies();
    data.companyStrategies.map((strategy) => {
      if (strategy.status === "ACTIVE") {
        this.activeCompanyStrategy = strategy;
      }
    });
    this.companyStrategies = data.companyStrategies;
  };

  createCompanyStrategy = async (companyStrategy) => {
    await companyService.createCompanyStrategy(companyStrategy);
    await this.getCompanyStrategies();
  };

  updateCompanyStrategy = async (companyStrategy, id) => {
    await companyService.updateCompanyStrategy(companyStrategy, id);
    await this.getCompanyStrategies();
  };

  deleteCompanyStrategy = async (id) => {
    await companyService.deleteCompanyStrategy(id);
    await this.getCompanyStrategies();
  };

  constructor() {
    makeObservable(this, {
      //Observables
      companyObjectives: observable,
      companyLoading: observable,
      selectedObjective: observable,
      objectiveDialogOpen: observable,
      endDate: observable,
      startDate: observable,
      oneOnOnes: observable,
      developmentSprintsData: observable,
      companyLibraryData: observable,
      companyObjectiveHistory: observable,
      companyObjectiveKrHistory: observable,
      companyUsers: observable,
      employeeRoles: observable,
      isTeamOkrFromKr: observable,
      employeeCertificates: observable,
      skillsTabIndex: observable,
      companyVisions: observable,
      activeCompanyVision: observable,
      companyStrategies: observable,
      activeCompanyStrategy: observable,
      companySlackAnswerData: observable,
      objectivesLoading: observable,
      // Actions
      getCompanyUsers: action,
      getEmployeeRoles: action,
      getCompanyObjectives: action,
      setActiveObjective: action,
      setObjectiveDialogState: action,
      createCompanyObjective: action,
      updateCompanyObjective: action,
      deleteCompanyObjective: action,
      createCompanyObjectiveKeyResult: action,
      updateCompanyObjectiveKeyResult: action,
      deleteCompanyObjectiveKeyResult: action,
      setStartDate: action,
      setEndDate: action,
      setSkillTabIndex: action,
      getOneOnOnes: action,
      setActiveObjectiveById: action,
      getDevelopmentSprintsData: action,
      getCompanyObjectiveHistory: action,
      getCompanyObjectiveKrHistory: action,
      setIsTeamOkrFromKr: action,
      getEmployeeCertificates: action,
      getCompanyLibraryData: action,
      getCompanyVisions: action,
      createCompanyVision: action,
      updateCompanyVision: action,
      deleteCompanyVision: action,
      getCompanyStrategies: action,
      createCompanyStrategy: action,
      updateCompanyStrategy: action,
      deleteCompanyStrategy: action,
      getCompanySlackAnswerData: action,
    });
  }
}
