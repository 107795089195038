import client from "../client";
import { gql } from "@apollo/client";

/* User related Queries and Mutations */

const GET_CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      roles
      userProfile {
        id
        name
        photoUrl
        title
        employeeRoleId
        futureEmployeeRoleId
        goal
        skillsToFocus {
          skill {
            id
            name
            skillCategory {
              id
              key
              name
            }
          }
        }
        skillLevels {
          id
          level
          verifiedLevel
          wantToWorkWith
          category
          skill {
            id
            name
            description
            skillCategory {
              id
              key
              name
              parentCategory {
                id
                key
                name
              }
            }
          }
        }
        education {
          id
          degreeName
          schoolName
          program
          notes
          startMonthYear
          endMonthYear
        }
        certificates {
          authority
          company
          name
          url
          licenseNumber
          startMonthYear
          endMonthYear
          id
          validUntil
        }
        currentJobStartDate
        dreams
      }
      userSettings {
        dateFormat
        lang
        showDream
        showPersonalData
        statusNotificationInterval
      }
    }
    hasUserSlackIntegration
    companyHasSlackIntegration
  }
`;

const GET_EXT_USER_PROFILE = gql`
  query userProfileForSearch($userProfileId: ID!) {
    userProfileForSearch(userProfileId: $userProfileId) {
      id
      userId
      name
      photoUrl
      title
      dreams
      skillsToFocus {
        skill {
          id
          name
          skillCategory {
            id
            key
            name
            parentCategory {
              name
            }
          }
        }
      }
      skillLevels {
        id
        level
        verifiedLevel
        wantToWorkWith
        category
        skill {
          id
          name
          description
          skillCategory {
            id
            key
            name
            parentCategory {
              name
            }
          }
        }
      }
      education {
        id
        degreeName
        schoolName
        program
        notes
        startMonthYear
        endMonthYear
      }
      certificates {
        authority
        company
        name
        url
        licenseNumber
        startMonthYear
        endMonthYear
      }
      currentJobStartDate
      userSettings {
        showPersonalData
      }
    }
  }
`;

const GET_USER_TEAMS = gql`
  query UserTeamsForDrops {
    userTeams {
      id
      objectives {
        id
        name
        type
        description
        dueDate
        keyResults {
          id
          completed
          title
          description
          dueDate
          evaluation
          initialValue
          targetValue
          learnings
          progress
          unit
          teamObjective {
            id
            name
            skills {
              skill {
                id
                name
                skillCategory {
                  id
                  key
                  name
                }
              }
            }
          }
        }
        companyObjective {
          description
          dueDate
          id
          name
          type
        }
      }
    }
  }
`;

const SEARCH_USER_WITH_MULTIPLE_SKILLS = gql`
  query userSearchBySkills($skills: [ID]!, $includeSelf: Boolean!) {
    userSearchBySkills(skills: $skills, includeSelf: $includeSelf) {
      user_id
      name
      id
      photoUrl
      title
      skillLevels {
        id
        skill {
          id
          name
          skillCategory {
            id
            key
            name
            parentCategory {
              name
            }
          }
        }
        level
      }
    }
  }
`;

const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings(
    $userId: ID!
    $updateUserSettingsInput: UpdateUserSettingsInput!
  ) {
    updateUserSettings(userId: $userId, input: $updateUserSettingsInput) {
      errors {
        key
        message
      }
      userSettings {
        lang
        showPersonalData
        showDream
      }
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $userId: ID!
    $updateUserProfileInput: UpdateUserProfileInput!
  ) {
    updateUserProfile(userId: $userId, input: $updateUserProfileInput) {
      userProfile {
        name
        photoUrl
        title
        goal
        skillLevels {
          id
          level
          category
          skill {
            id
            name
            skillCategory {
              id
              key
              name
              parentCategory {
                name
              }
            }
          }
        }
        education {
          id
          degreeName
          schoolName
          program
          notes
          startMonthYear
          endMonthYear
        }
        certificates {
          authority
          company
          name
          url
          licenseNumber
          startMonthYear
          endMonthYear
        }
        currentJobStartDate
        dreams
      }
      errors {
        key
        message
      }
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation uploadImage($image: Upload!) {
    uploadImage(image: $image) {
      errors {
        key
        message
      }
    }
  }
`;

const DELETE_SLACK_INTEGRATION = gql`
  mutation DeleteUserSlackIntegration {
    deleteUserSlackIntegration
  }
`;

const GET_PERSONAL_COMMENTS = gql`
  query UserPersonalComments($userId: ID!) {
    userPersonalComments(userId: $userId) {
      id
      comment
      insertedAt
      from {
        name
        id
        photoUrl
      }
    }
  }
`;

const CREATE_FEEDBACK = gql`
  mutation ($comment: PersonalCommentInput!) {
    createPersonalComment(input: $comment) {
      errors
    }
  }
`;

const GET_1ON1S = gql`
  query OneOnOnes($userId: ID!) {
    oneOnOnes(userId: $userId) {
      insertedAt
      id
      memo
      checkpoint {
        id
      }
      from {
        id
        userProfile {
          id
          name
        }
      }
    }
  }
`;
const CREATE_1ON1 = gql`
  mutation ($memo: OneOnOneInput!) {
    createOneOnOne(input: $memo) {
      errors
    }
  }
`;

const UPDATE_1ON1 = gql`
  mutation ($memo: OneOnOneInput!, $id: ID!) {
    updateOneOnOne(input: $memo, oneOnOneId: $id) {
      errors
    }
  }
`;

const LOGOUT = gql`
  mutation {
    logout {
      success
    }
  }
`;

const GET_PENDING_EVALUATIONS = gql`
  query pendingUserEvaluations {
    pendingUserEvaluations {
      id
      answered
      sent
      newAnswerSeen
      type
      from {
        userProfile {
          name
          photoUrl
          title
        }
      }
    }
  }
`;

const GET_USER_EVALUATION_DATES = gql`
  query userEvaluationDates {
    userEvaluationDates {
      date
      evaluationType
      for
    }
  }
`;

/* Actions for fetching and mutating data */

export const getCurrentUser = async () => {
  const res = await client.query({
    query: GET_CURRENT_USER,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getUserTeams = async () => {
  const res = await client.query({
    query: GET_USER_TEAMS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getUserFeedback = async (userId) => {
  const res = await client.query({
    query: GET_PERSONAL_COMMENTS,
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getOneOnOnes = async (userId) => {
  const res = await client.query({
    query: GET_1ON1S,
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createOneOnOne = async (memo) => {
  const res = await client.mutate({
    mutation: CREATE_1ON1,
    variables: {
      memo: memo,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateOneOnOne = async (memo, oneOnOneId) => {
  const res = await client.mutate({
    mutation: UPDATE_1ON1,
    variables: {
      memo: memo,
      id: oneOnOneId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getExtProfile = async (userId) => {
  const res = await client.query({
    query: GET_EXT_USER_PROFILE,
    variables: {
      userProfileId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createFeedback = async (
  fromId,
  forId,
  feedbackComment,
  isAnonymous
) => {
  const res = await client.mutate({
    mutation: CREATE_FEEDBACK,
    fetchPolicy: "no-cache",
    variables: {
      comment: {
        fromId: fromId,
        forId: forId,
        comment: feedbackComment,
        isAnonymous: isAnonymous,
      },
    },
  });
  return res.data;
};

export const uploadImage = async (image) => {
  const res = await client.mutate({
    mutation: UPLOAD_IMAGE,
    fetchPolicy: "no-cache",
    variables: {
      image: image,
    },
  });
  return res.data;
};

export const deleteSlackIntegration = async () => {
  const res = await client.mutate({
    mutation: DELETE_SLACK_INTEGRATION,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateUserSettings = async (userId, settingsUpdate) => {
  const res = await client.mutate({
    mutation: UPDATE_USER_SETTINGS,
    fetchPolicy: "no-cache",
    variables: {
      userId: userId,
      updateUserSettingsInput: settingsUpdate,
    },
  });
  return res.data;
};

export const updateUserProfile = async (userId, profileUpdate) => {
  const res = await client.mutate({
    mutation: UPDATE_USER_PROFILE,
    fetchPolicy: "no-cache",
    variables: {
      userId: userId,
      updateUserProfileInput: profileUpdate,
    },
  });
  return res.data;
};

export const searchUserWithMultipleSkills = async (skills, includeSelf) => {
  const res = await client.mutate({
    mutation: SEARCH_USER_WITH_MULTIPLE_SKILLS,
    fetchPolicy: "no-cache",
    variables: {
      skills: skills,
      includeSelf: includeSelf,
    },
  });
  return res.data;
};

export const logout = async () => {
  const res = await client.mutate({
    mutation: LOGOUT,
  });
  return res.data;
};

export const getPendingEvaluations = async () => {
  const res = await client.query({
    query: GET_PENDING_EVALUATIONS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getEvaluationDates = async () => {
  const res = await client.query({
    query: GET_USER_EVALUATION_DATES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};
