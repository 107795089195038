import { makeObservable, observable, action } from "mobx";
import * as coachService from "../services/coachService";
import * as goalService from "../services/goalService";
import * as userService from "../services/userService";

export class CoachStore {
  coachees = [];
  activeCheckpoint = null;
  coachLoading = false;
  coachDialogOpen = false;
  selectedCoachee = null;
  isHeldLoading = false;
  currentCoachees = [];
  pastCoachees = [];
  userSlackAnswerData = null;
  coacheeLoading = false;

  getCoachees = async (userId) => {
    this.coachLoading = true;
    let coachees = null;
    const coacheesCurrent = [];
    const coacheesPast = [];
    coachService.getCoachees().then(async (data) => {
      this.currentCoachees = [];
      this.pastCoachees = [];
      this.coacheeLoading = true;
      if (data) {
        coachees = data.coachees;
        await Promise.all(
          coachees.map(async (coachee) => {
            userService.getOneOnOnes(coachee.userId).then((data) => {
              coachee.oneOnOnes = data.oneOnOnes;
              this.coachees = coachees;
            });
            coachService.getCoachUserMemos(coachee.userId).then((data) => {
              coachee.memos = data.coachUserMemos;
              this.coachees = coachees;
            });
            const data = await coachService.getCoacheeSprints(coachee.userId);
            coachee.sprints = data.userDevelopmentSprints;
            this.coachees = coachees;
            if (data.userDevelopmentSprints) {
              const activeSprint =
                data.userDevelopmentSprints &&
                data.userDevelopmentSprints.find(
                  (sprint) => sprint.status === "ACTIVE"
                );

              if (activeSprint) {
                if (activeSprint.coach && activeSprint.coach.id === userId) {
                  coacheesCurrent.push(coachee);
                } else if (
                  activeSprint.hrPerson &&
                  activeSprint.hrPerson.id === userId
                ) {
                  coacheesCurrent.push({ ...coachee, isHr: true });
                } else {
                  if (
                    data.userDevelopmentSprints &&
                    data.userDevelopmentSprints
                      .slice()
                      .find((sprint) => sprint.coach.id === userId)
                  ) {
                    coacheesPast.push({ ...coachee, isPastCoachee: true });
                  } else {
                    coacheesPast.push({
                      ...coachee,
                      isPastCoachee: true,
                      isHr: true,
                    });
                  }
                }
              } else {
                if (
                  data.userDevelopmentSprints &&
                  data.userDevelopmentSprints
                    .slice()
                    .find(
                      (sprint) => sprint.coach && sprint.coach.id === userId
                    )
                ) {
                  coacheesPast.push({ ...coachee, isPastCoachee: true });
                } else {
                  coacheesPast.push({
                    ...coachee,
                    isPastCoachee: true,
                    isHr: true,
                  });
                }
              }
            }
          })
        );
        this.currentCoachees = coacheesCurrent;
        this.pastCoachees = coacheesPast;
      }
      this.coacheeLoading = false;
      this.coachLoading = false;
    });

    return coachees;
  };

  getCoacheesDetails = (userId) => {};

  updateCheckpointHeld = async (data, id, coacheeId, userId) => {
    this.isHeldLoading = true;
    await goalService.updateCheckpointHeld(data, id);
    this.activeCheckpoint.oneOnOneHeld = data.oneOnOneHeld;
    this.isHeldLoading = false;
  };

  getCoachUserMemos = async (coacheeId) => {
    this.coachLoading = true;
    let data = await coachService.getCoachUserMemos(coacheeId);
    this.selectedCoachee.memos = data.coachUserMemos;
    this.coachLoading = false;
  };

  getCoacheeOneOnOnes = async (coacheeId) => {
    this.coachLoading = true;
    let data = await userService.getOneOnOnes(coacheeId);
    this.selectedCoachee.oneOnOnes = data.oneOnOnes;
    this.coachLoading = false;
  };

  createCoachMemo = async (coachId, coacheeId, memo) => {
    this.coachLoading = true;
    const input = { coachId: coachId, userId: coacheeId, memo: memo };
    await coachService.createCoachMemo(input).then((res) => {
      this.coachLoading = false;
    });
    this.getCoachUserMemos(coacheeId);
  };

  deleteCoachMemo = async (coachMemoId) => {
    this.coachLoading = true;
    await coachService.deleteCoachMemo(coachMemoId);
    this.selectedCoachee.memos = this.selectedCoachee.memos.filter((memo) => {
      return memo.id !== coachMemoId;
    });
    this.coachLoading = false;
  };

  setDialogState = (state) => {
    this.coachDialogOpen = state;
  };

  setCoachee = async (id) => {
    const allCoachees = [...this.currentCoachees, ...this.pastCoachees];
    this.selectedCoachee = allCoachees.find((coachee) => coachee.id === id);
  };

  getUserSlackAnswerData = async (userId) => {
    let data = await coachService.getUserSlackAnswerData(userId);
    this.userSlackAnswerData = data.userSlackAnswerData;
  };

  setActiveCheckpoint = async (checkpoint) => {
    this.activeCheckpoint = checkpoint;
  };

  resetStore = () => {
    this.coachees = [];
    this.pastCoachees = [];
    this.currentCoachees = [];
    this.coachLoading = false;
    this.coachDialogOpen = false;
    this.selectedCoachee = null;
    this.activeCheckpoint = null;
  };

  constructor() {
    makeObservable(this, {
      coachLoading: observable,
      activeCheckpoint: observable,
      coachDialogOpen: observable,
      selectedCoachee: observable,
      isHeldLoading: observable,
      coachees: observable,
      currentCoachees: observable,
      pastCoachees: observable,
      userSlackAnswerData: observable,
      coacheeLoading: observable,
      getCoachees: action,
      getCoachUserMemos: action,
      getCoacheeOneOnOnes: action,
      setCoachee: action,
      setDialogState: action,
      updateCheckpointHeld: action,
      resetStore: action,
      getUserSlackAnswerData: action,
      setActiveCheckpoint: action,
    });
  }
}
