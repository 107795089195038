import client from "../client";
import { gql } from "@apollo/client";

/* Team related queries and mutations */

const GET_TEAM = gql`
  query getTeam($teamId: ID!) {
    teamSkills(teamId: $teamId) {
      average
      skill {
        id
        name
        skillCategory {
          name
        }
      }
    }
    getTeam(teamId: $teamId) {
      id
      name
      teamLead {
        id
        email
        userProfile {
          id
          name
          photoUrl
          title
        }
      }
      members {
        id
        email
        userProfile {
          id
          name
          photoUrl
          title
          skillLevels {
            id
            level
            skill {
              name
              skillCategory {
                name
                id
                parentCategory {
                  name
                }
              }
            }
          }
        }
      }
      objectives {
        id
        name
        type
        description
        dueDate
        skills {
          skill {
            id
            name
            skillCategory {
              name
              id
            }
          }
        }
        companyObjectiveKeyResult {
          id
          completed
          title
          description
          dueDate
          evaluation
          initialValue
          targetValue
          progress
          score
          unit
          companyObjective {
            id
            name
            skills {
              skill {
                id
                name
                skillCategory {
                  id
                  key
                  name
                }
              }
            }
          }
        }
        teamObjectiveKeyResult {
          id
          completed
          title
          description
          dueDate
          evaluation
          initialValue
          targetValue
          progress
          unit
          teamObjective {
            id
            name
            skills {
              skill {
                id
                name
                skillCategory {
                  id
                  key
                  name
                }
              }
            }
          }
        }
        keyResults {
          id
          completed
          title
          description
          dueDate
          evaluation
          initialValue
          targetValue
          learnings
          score
          progress
          unit
          type
          teamObjective {
            name
            id
            dueDate
          }
          owner {
            id
            userProfile {
              name
              photoUrl
            }
          }
          goals {
            id
            name
            completed
            dueDate
            tasks {
              id
              completed
              state
              completionPercentage
              name
            }
            user {
              id
              userProfile {
                id
                name
              }
            }
          }
        }
        companyObjective {
          id
          name
          description
        }
        team {
          id
          name
        }
        owner {
          id
          userProfile {
            name
            photoUrl
          }
        }
        goals {
          id
          name
          completed
          dueDate
          tasks {
            id
            completed
            state
            completionPercentage
          }
          user {
            id
            userProfile {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const GET_TEAM_SKILLS = gql`
  query teamSkills($teamId: ID!) {
    teamSkills(teamId: $teamId) {
      average
      skill {
        id
        name
        skillCategory {
          name
        }
      }
    }
  }
`;

const GET_TEAMS = gql`
  query {
    userTeams {
      id
      name
      teamLead {
        id
        email
        userProfile {
          id
          name
          photoUrl
          title
        }
      }
      objectives {
        id
        name
        type
        description
        dueDate
        keyResults {
          id
          completed
          title
          description
          dueDate
          evaluation
          initialValue
          targetValue
          learnings
          progress
          unit
          type
          teamObjective {
            id
            name
            skills {
              skill {
                id
                name
                skillCategory {
                  id
                  key
                  name
                }
              }
            }
          }

          goals {
            id
            name
            completed
            dueDate
            user {
              id
              userProfile {
                id
                name
              }
            }
          }
        }
      }
      members {
        id
        email
        userProfile {
          id
          name
          photoUrl
          title
          skillLevels {
            id
            level
            skill {
              name
              skillCategory {
                name
                id
                parentCategory {
                  name
                }
              }
            }
          }
        }
      }
    }
    teams {
      id
      name
      objectives {
        id
        name
        type
        description
        dueDate
        keyResults {
          id
          completed
          title
          description
          dueDate
          evaluation
          initialValue
          targetValue
          learnings
          progress
          unit
          type
          teamObjective {
            id
            name
            skills {
              skill {
                id
                name
                skillCategory {
                  id
                  key
                  name
                }
              }
            }
          }

          goals {
            id
            name
            completed
            dueDate
            user {
              id
              userProfile {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GET_USER_GOALS = gql`
  query UserGoalsForSearch($userId: ID!) {
    userGoalsForSearch(userId: $userId) {
      id
      name
      description
      dueDate
      completed
      tasks {
        id
        completed
        state
        completionPercentage
      }
    }
  }
`;

// Team objecvtive mutations

const CREATE_TEAM_OBJECTIVE = gql`
  mutation ($objective: TeamObjectiveInput!) {
    createTeamObjective(input: $objective) {
      errors {
        key
        message
      }
      teamObjective {
        id
      }
    }
  }
`;

const UPDATE_TEAM_OBJECTIVE = gql`
  mutation ($teamObjectiveId: ID!, $objective: TeamObjectiveInput!) {
    updateTeamObjective(input: $objective, teamObjectiveId: $teamObjectiveId) {
      errors {
        key
        message
      }
      teamObjective {
        id
        name
        type
        description
        dueDate
        skills {
          skill {
            id
            name
            skillCategory {
              name
              id
            }
          }
        }
        companyObjective {
          id
          name
          description
        }
      }
    }
  }
`;

const DELETE_TEAM_OBJECTIVE = gql`
  mutation ($teamObjectiveId: ID!) {
    deleteTeamObjective(teamObjectiveId: $teamObjectiveId) {
      errors {
        key
        message
      }
    }
  }
`;

// Create key result mutations

const CREATE_TEAM_OBJECTIVE_KEY_RESULT = gql`
  mutation ($input: TeamObjectiveKeyResultInput!) {
    createTeamObjectiveKeyResult(input: $input) {
      errors {
        key
        message
      }
      teamObjectiveKeyResult {
        id
      }
    }
  }
`;

const UPDATE_TEAM_OBJECTIVE_KEY_RESULT = gql`
  mutation (
    $teamObjectiveKeyResultId: ID!
    $input: TeamObjectiveKeyResultInput!
  ) {
    updateTeamObjectiveKeyResult(
      input: $input
      teamObjectiveKeyResultId: $teamObjectiveKeyResultId
    ) {
      errors {
        key
        message
      }
      teamObjectiveKeyResult {
        id
      }
    }
  }
`;

const DELETE_TEAM_OBJECTIVE_KEY_RESULT = gql`
  mutation ($teamObjectiveKeyResultId: ID!) {
    deleteTeamObjectiveKeyResult(
      teamObjectiveKeyResultId: $teamObjectiveKeyResultId
    ) {
      errors {
        key
        message
      }
    }
  }
`;

const GET_TEAM_OBJECTIVE_HISTORY = gql`
  query teamObjectiveHistory($teamObjectiveId: ID!) {
    teamObjectiveHistory(teamObjectiveId: $teamObjectiveId) {
      action
      recordedAt
      patch {
        companyObjectiveId
        companyObjectiveKeyResultId
        description
        dueDate
        learnings
        name
        ownerId
        startDate
        teamId
        teamObjectiveKeyResultId
        type
      }
      actor {
        id
        userProfile {
          id
          name
          photoUrl
        }
      }
    }
  }
`;

const GET_TEAM_OBJECTIVE_KR_HISTORY = gql`
  query teamObjectiveKeyResultHistory($teamObjectiveKeyResultId: ID!) {
    teamObjectiveKeyResultHistory(
      teamObjectiveKeyResultId: $teamObjectiveKeyResultId
    ) {
      action
      recordedAt
      notes
      patch {
        completed
        description
        dueDate
        initialValue
        learnings
        progress
        title
        ownerId
        targetValue
        unit
        score
      }
      actor {
        id
        userProfile {
          id
          name
          photoUrl
        }
      }
    }
  }
`;

const GET_TEAMS_DETAILS = gql`
  query {
    teams {
      id
      name
      objectives {
        id
        name
        type
        description
        dueDate
        keyResults {
          id
          completed
          title
          description
          dueDate
          evaluation
          initialValue
          targetValue
          learnings
          progress
          unit
          teamObjective {
            id
            name
            skills {
              skill {
                id
                name
                skillCategory {
                  id
                  key
                  name
                }
              }
            }
          }

          goals {
            id
            name
            completed
            dueDate
            user {
              id
              userProfile {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GET_ALL_OBJECTIVES = gql`
  query {
    teamObjectives {
      id
      name
      type
      description
      dueDate
      keyResults {
        id
        completed
        title
        description
        dueDate
        evaluation
        initialValue
        targetValue
        learnings
        progress
        unit
        type
        teamObjective {
          id
          name
          skills {
            skill {
              id
              name
              skillCategory {
                id
                key
                name
              }
            }
          }
        }

        goals {
          id
          name
          completed
          dueDate
          user {
            id
            userProfile {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const GET_TEAM_SLACK_ANSWER_DATA = gql`
  query teamSlackAnswerData($teamId: ID!) {
    teamSlackAnswerData(teamId: $teamId) {
      first {
        avg
        weeklyAvgs {
          avg
          week
          year
        }
      }
      second {
        avg
        weeklyAvgs {
          avg
          week
          year
        }
      }
      third
      fourth
    }
  }
`;

/* Actions for fetching and mutating data */

export const getTeam = async (teamId) => {
  const res = await client.query({
    query: GET_TEAM,
    variables: {
      teamId: teamId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getAllObjectives = async (teamId) => {
  const res = await client.query({
    query: GET_ALL_OBJECTIVES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getUserGoals = async (userId) => {
  const res = await client.query({
    query: GET_USER_GOALS,
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getAllTeams = async () => {
  const res = await client.query({
    query: GET_TEAMS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getAllTeamsDetails = async () => {
  const res = await client.query({
    query: GET_TEAMS_DETAILS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getTeamSkills = async (teamId) => {
  const res = await client.query({
    query: GET_TEAM_SKILLS,
    variables: {
      teamId: teamId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createTeamObjective = async (data) => {
  const res = await client.mutate({
    mutation: CREATE_TEAM_OBJECTIVE,
    variables: {
      objective: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateTeamObjective = async (data, teamObjectiveId) => {
  const res = await client.mutate({
    mutation: UPDATE_TEAM_OBJECTIVE,
    variables: {
      teamObjectiveId: teamObjectiveId,
      objective: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const deleteTeamObjective = async (objectiveId) => {
  const res = await client.mutate({
    mutation: DELETE_TEAM_OBJECTIVE,
    variables: {
      teamObjectiveId: objectiveId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createTeamObjectiveKeyResult = async (data) => {
  const res = await client.mutate({
    mutation: CREATE_TEAM_OBJECTIVE_KEY_RESULT,
    variables: {
      input: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateTeamObjectiveKeyResult = async (
  data,
  teamObjectiveKeyResultId
) => {
  const res = await client.mutate({
    mutation: UPDATE_TEAM_OBJECTIVE_KEY_RESULT,
    variables: {
      teamObjectiveKeyResultId: teamObjectiveKeyResultId,
      input: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const deleteTeamObjectiveKeyResult = async (
  teamObjectiveKeyResultId
) => {
  const res = await client.mutate({
    mutation: DELETE_TEAM_OBJECTIVE_KEY_RESULT,
    variables: {
      teamObjectiveKeyResultId: teamObjectiveKeyResultId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getTeamObjectiveHistory = async (teamObjectiveId) => {
  const res = await client.query({
    query: GET_TEAM_OBJECTIVE_HISTORY,
    variables: {
      teamObjectiveId: teamObjectiveId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getTeamObjectiveKrHistory = async (teamObjectiveKRId) => {
  const res = await client.query({
    query: GET_TEAM_OBJECTIVE_KR_HISTORY,
    variables: {
      teamObjectiveKeyResultId: teamObjectiveKRId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getTeamSlackAnswerData = async (teamId) => {
  const res = await client.query({
    query: GET_TEAM_SLACK_ANSWER_DATA,
    variables: {
      teamId: teamId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};
