import { createTheme } from "@material-ui/core/styles";

// Material UI theme overrides
const theme = createTheme({
  success: {
    zIndex: 9999999999,
    fontSize: "120px",
    color: "red",
  },
  palette: {
    primary: {
      light: "#57cabe",
      main: "#11998e",
      dark: "#006a61",
      contrastText: "#fff",
    },
    secondary: {
      light: "#7bffae",
      main: "#38ef7d",
      dark: "#00bb4e",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: "Sora",
  },
  overrides: {
    MuiTextField: {
      root: {
        background: "#fff",
        borderRadius: "20px",
        "&:hover": {
          backgroundColor: "none",
          borderRadius: "20px",
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "none",
        borderRadius: "20px",
        borderBottom: "none",
        "&$focused": {
          backgroundColor: "none",
          borderRadius: "20px",
          borderBottom: "none",
        },
        "&:hover": {
          backgroundColor: "none",
          borderRadius: "20px",
          borderBottom: "none",
        },
        "&:focused": {
          backgroundColor: "none",
          borderRadius: "20px",
        },
      }
    },
    MuiPaper: {
      root: {
        borderRadius: "20px",
        boxShadow: "rgba(117, 117, 117, 0.12) 3px 3px 13px;",
      },
      rounded: {
        borderRadius: "20px",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "20px",
      },
    },
    MuiSelect: {
      root: {
        minWidth: "100px",
        borderRadius: "20px",
      },
      selectMenu: {
        minWidth: "100px",
        borderRadius: "20px",
        borderColor: "#f5f5f5",
        boxShadow: "rgba(117, 117, 117, 0.12) 3px 3px 13px;",
      },
    },
    MuiTabs: {
      root: {
        borderRadius: 0,
        minHeight: 10,
      },
      flexContainer: {
        display: "inline-flex",
        position: "relative",
      },
      indicator: {
        top: 5,
        bottom: 5,
        right: 5,
        left: 5,
        background: "none",
        "&:after": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          left: 5,
          bottom: 0,
          minHeight: 30,
          borderRadius: 20,
          backgroundColor: "rgba(255,255,255,0.1)",
        },
      },
    },
    MuiTab: {
      root: {
        "&:hover": {
          opacity: 1,
        },
        minHeight: 10,
        minWidth: 50,
        marginLeft: 10,
        borderRadius: 20,
        backgroundColor: "#ebebeb",
      },
      wrapper: {
        textTransform: "initial",
        fontSize: 12,
        fontWeight: "500",
        borderRadius: 30,
      },
    },
  },
});

export default theme;
