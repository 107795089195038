import React from 'react';
import { withContext } from "with-context";

export const AuthContext = React.createContext();
export const withAuth = withContext(AuthContext, "auth");

class AuthProvider extends React.Component {
    constructor(props) {
        super(props);

        this.setCurrentUser = (currentUser) => {
            window.localStorage.setItem('currentUser', JSON.stringify(currentUser));
        };

        this.getCurrentUser = () => {
            return JSON.parse(window.localStorage.getItem('currentUser'));
        }

        this.isCompanyAdmin = () => {
            return JSON.parse(window.localStorage.getItem('currentUser')).roles.includes("company_admin");
        }

        this.logout = () => {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('currentUser');
            this.setState(state => {
                return {
                    currentUser: null
                };
            });
        }

        this.state = {
            getCurrentUser: this.getCurrentUser,
            setCurrentUser: this.setCurrentUser,
            isCompanyAdmin: this.isCompanyAdmin,
            logout: this.logout
        };
    }

    render() {
        return (
            <AuthContext.Provider value={this.state}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default AuthProvider;