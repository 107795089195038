import { makeObservable, observable, action } from "mobx";
import * as libraryService from "../services/libraryService";
import * as adminService from "../services/adminService";
export class LibraryStore {
  careerPaths = [];
  companyLearningPaths = [];
  learningPaths = [];
  tagArray = [];
  activePath = null;
  activeCareerPath = null;
  pathsLoading = false;
  detailsDialogOpen = false;
  careerDetailsDialogOpen = false;
  selectedSkills = [];
  filteredTags = [];
  filterKeywords = [];
  tabIndex = 0;

  getPublicLearningPaths = async () => {
    this.pathsLoading = true;
    let data = await libraryService.getPublicLearningPaths();
    this.learningPaths = data.publicLearningPaths;
    this.pathsLoading = false;
  };

  getCompanyCareerPaths = async () => {
    let data = await adminService.getCompanyCareerPaths();
    this.careerPaths = data.companyCareerPaths;
  };

  getCompanyLearningPaths = async () => {
    let data = await adminService.getCompanyLearningPaths();
    this.companyLearningPaths = data.companyLearningPaths;
    return this.companyLearningPaths;
  };

  addPathToDevPath = async (pathId, userLang) => {
    this.pathsLoading = true;
    const result = await libraryService.createPathToDevPath(pathId, userLang);
    this.pathsLoading = false;
    return result.createPathFromPublicLearningPath;
  };

  addCompanyLearningPathToDevPath = async (pathId, userLang) => {
    this.pathsLoading = true;
    const result = await libraryService.createCompanyPathToDevPath(
      pathId,
      userLang
    );
    this.pathsLoading = false;
    return result.createPathFromCompanyLearningPath;
  };

  addCareerPathToDevPath = async (pathId, userLang) => {
    this.pathsLoading = true;
    let data = await libraryService.createCareerPathToDevPath(pathId, userLang);
    this.pathsLoading = false;
    return data;
  };

  addTag = (tag) => {
    this.tagArray = [...this.tagArray, tag];
  };

  removeTag = (tag) => {
    var newTagArray = this.tagArray.filter((t) => t.id !== tag.id);
    this.tagArray = newTagArray;
  };

  clearTags = () => {
    this.tagArray = [];
  };

  setDialogState = (state) => {
    this.detailsDialogOpen = state;
  };

  setCareerDialogState = (state) => {
    this.careerDetailsDialogOpen = state;
  };

  setActivePath = (path, companyLearningPath) => {
    this.activePath = path;
    this.activePath.companyLearningPath = companyLearningPath;
  };

  setActiveCareerPath = (path) => {
    this.activeCareerPath = path;
  };

  setSelectedSkills = (skills) => {
    this.selectedSkills = skills;
  };

  clearSelectedSkills = () => {
    this.selectedSkills = [];
  };

  setFilteredTags = (tags) => {
    this.filteredTags = tags;
  };

  setFilterKeywords = (keywords) => {
    this.filterKeywords = keywords;
  };

  setTabIndex = (index) => {
    this.tabIndex = index;
  };

  constructor() {
    makeObservable(this, {
      learningPaths: observable,
      careerPaths: observable,
      pathsLoading: observable,
      tagArray: observable,
      detailsDialogOpen: observable,
      activePath: observable,
      activeCareerPath: observable,
      careerDetailsDialogOpen: observable,
      companyLearningPaths: observable,
      selectedSkills: observable,
      filteredTags: observable,
      tabIndex: observable,
      filterKeywords: observable,
      getPublicLearningPaths: action,
      getCompanyLearningPaths: action,
      getCompanyCareerPaths: action,
      addCompanyLearningPathToDevPath: action,
      addTag: action,
      removeTag: action,
      clearTags: action,
      setDialogState: action,
      setCareerDialogState: action,
      setActiveCareerPath: action,
      setActivePath: action,
      addPathToDevPath: action,
      setSelectedSkills: action,
      clearSelectedSkills: action,
      setFilteredTags: action,
      setFilterKeywords: action,
      setTabIndex: action,
    });
  }
}
