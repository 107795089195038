import React, { Suspense, lazy } from "react";
import { configure } from "mobx";
import { Switch, Route, Redirect } from "react-router-dom";

//Material UI ThemeProvider and theme
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./styles/theme.js";

//Snackbar provider with styles for notifications
import { SnackbarProvider } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import snackBarStyles from "./styles/theme.js";

//Authentication and AuthContext provider
import AuthProvider from "./authContext/authContext";
import { AuthContext } from "./authContext/authContext";

//MobX Store context provider and config
import { StoresContext, useStores } from "./store/stores";

import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [],
  });
}

configure({ enforceActions: "never", useProxies: "never" });

const WithNavigation = lazy(() =>
  import("./components/Navigation/WithNavigation").catch((e) =>
    window.location.reload()
  )
);
const DashboardPersonal = lazy(() =>
  import("./components/Reporting/DashboardPersonal").catch((e) =>
    window.location.reload()
  )
);
const ReportingTeam = lazy(() =>
  import("./components/Reporting/DashboardTeam").catch((e) =>
    window.location.reload()
  )
);
const ReportingCompany = lazy(() =>
  import("./components/Reporting/DashboardCompany").catch((e) =>
    window.location.reload()
  )
);
const ExtFeedbackPage = lazy(() =>
  import("./components/Feedback/ExtFeedbackPage").catch((e) =>
    window.location.reload()
  )
);
const FeedbackPage = lazy(() =>
  import("./components/Feedback/FeedbackPage").catch((e) =>
    window.location.reload()
  )
);
const FeedbackRequestsPage = lazy(() =>
  import("./components/Feedback/FeedbackRequestsPage").catch((e) =>
    window.location.reload()
  )
);
const AskFeedback = lazy(() =>
  import("./components/Feedback/AskFeedbackPage").catch((e) =>
    window.location.reload()
  )
);
const ProfileWrapper = lazy(() =>
  import("./components/Profile/ProfileWrapper").catch((e) =>
    window.location.reload()
  )
);
const AdminWrapper = lazy(() =>
  import("./components/Admin/AdminWrapper").catch((e) =>
    window.location.reload()
  )
);
const Settings = lazy(() =>
  import("./components/Settings").catch((e) => window.location.reload())
);
const WizardPage = lazy(() =>
  import("./components/Wizard/Wizard.js").catch((e) => window.location.reload())
);

const SkillEvaluation = lazy(() =>
  import("./components/Profile/EvaluateSkills").catch((e) =>
    window.location.reload()
  )
);

const CoachEvaluation = lazy(() =>
  import("./components/Sprint/CoachEvaluation/Evaluation").catch((e) =>
    window.location.reload()
  )
);

const PeerEvaluation = lazy(() =>
  import("./components/Sprint/PeerEvaluation/PeerEvaluation").catch((e) =>
    window.location.reload()
  )
);

const ExtProfileWrapper = lazy(() =>
  import("./components/Profile/ExtProfileWrapper").catch((e) =>
    window.location.reload()
  )
);
const DevPath = lazy(() =>
  import("./components/Path/DevPath")
    .catch((_e) => window.location.reload())
    .catch((e) => window.location.reload())
);
const PlanSprint = lazy(() =>
  import("./components/Sprint/PlanSprint").catch((_e) =>
    window.location.reload()
  )
);
const Checkpoint = lazy(() =>
  import("./components/Sprint/Checkpoint").catch((e) =>
    window.location.reload()
  )
);
const Login = lazy(() =>
  import("./components/Login").catch((e) => window.location.reload())
);
const ResetPassword = lazy(() =>
  import("./components/ResetPass").catch((e) => window.location.reload())
);
const Evaluation = lazy(() =>
  import("./components/Sprint/Evaluation/Evaluation").catch((e) =>
    window.location.reload()
  )
);
const Library = lazy(() =>
  import("./components/Library/Library").catch((e) => window.location.reload())
);
const CoachPage = lazy(() =>
  import("./components/Coach/CoachPage").catch((e) => window.location.reload())
);
const OneOnOnes = lazy(() =>
  import("./components/1on1/OneOnOnes").catch((e) => window.location.reload())
);
const SlackSuccess = lazy(() =>
  import("./components/SlackSuccess").catch((e) => window.location.reload())
);
const SetNewPassword = lazy(() =>
  import("./components/SetNewPassword").catch((e) => window.location.reload())
);
const SignUpCompany = lazy(() =>
  import("./components/SignUpCompany").catch((e) => window.location.reload())
);
const SignUpUser = lazy(() =>
  import("./components/SignUpUser").catch((e) => window.location.reload())
);
const SearchPage = lazy(() =>
  import("./components/Common/SearchPage").catch((e) =>
    window.location.reload()
  )
);
const CoacheeDetailsPage = lazy(() =>
  import("./components/Coach/CoacheeDetailsPage").catch((e) =>
    window.location.reload()
  )
);

const CoacheeOneOnOnePage = lazy(() =>
  import("./components/Coach/CoacheeOneOnOnePage").catch((e) =>
    window.location.reload()
  )
);
const Review = lazy(() =>
  import("./components/Sprint/Review/Review").catch((e) =>
    window.location.reload()
  )
);
const ManageLibrary = lazy(() =>
  import("./components/Admin/AdminLibrary").catch((e) =>
    window.location.reload()
  )
);

const CreateLibraryPath = lazy(() =>
  import("./components/Admin/CreatePath").catch((e) => window.location.reload())
);

const App = () => {
  const stores = useStores();
  // Add close action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <Sentry.ErrorBoundary fallback={"There was an error. Please refresh page."}>
      <ThemeProvider theme={theme}>
        <StoresContext.Provider value={stores}>
          <SnackbarProvider
            maxSnack={3}
            classes={{
              root: snackBarStyles.snackBar,
              variantSuccess: snackBarStyles.success,
              variantError: snackBarStyles.error,
              variantWarning: snackBarStyles.warning,
              variantInfo: snackBarStyles.info,
            }}
            ref={notistackRef}
            action={(key) => (
              <FontAwesomeIcon
                icon={faTimes}
                style={{ cursor: "pointer" }}
                className="mr-2"
                onClick={onClickDismiss(key)}
              />
            )}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            style={{ zIndex: "999999999999999999999999 !important" }}
          >
            <AuthProvider>
              <Suspense fallback={<div></div>}>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/resetpass" component={ResetPassword} />
                  <Route
                    exact
                    path="/setnewpassword"
                    component={SetNewPassword}
                  />
                  <Route exact path="/signup" component={SignUpCompany} />
                  <Route exact path="/finishsignup" component={SignUpUser} />
                  <Route
                    exact
                    path="/feedback/answer/:token"
                    component={ExtFeedbackPage}
                  />
                  <PrivateRoute exact path="/" component={DashboardPersonal} />
                  <PrivateRoute
                    exact
                    path="/dashboard"
                    component={DashboardPersonal}
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard/personal"
                    component={DashboardPersonal}
                  />
                  <PrivateRoute
                    exact
                    path="/reporting/team"
                    component={ReportingTeam}
                  />
                  <PrivateRoute
                    exact
                    path="/reporting/company"
                    component={ReportingCompany}
                  />
                  <PrivateRoute exact path="/wizard" component={WizardPage} />
                  <PrivateRoute
                    exact
                    path="/evaluate-skills"
                    component={SkillEvaluation}
                  />
                  <PrivateRoute
                    exact
                    path="/profile"
                    component={ProfileWrapper}
                  />
                  <PrivateRoute
                    exact
                    path="/feedback"
                    component={FeedbackPage}
                  />
                  <PrivateRoute
                    exact
                    path="/feedback/ask"
                    component={AskFeedback}
                  />
                  <PrivateRoute
                    exact
                    path="/feedback/requests"
                    component={FeedbackRequestsPage}
                  />
                  <PrivateRoute exact path="/settings" component={Settings} />
                  <PrivateRoute
                    exact
                    path="/view/:id"
                    component={ExtProfileWrapper}
                  />
                  <PrivateRoute exact path="/sprint" component={DevPath} />
                  <PrivateRoute
                    exact
                    path="/sprint/checkpoint/:id"
                    component={Checkpoint}
                  />
                  <PrivateRoute
                    exact
                    path="/sprint/plan"
                    component={PlanSprint}
                  />
                  <PrivateRoute
                    exact
                    path="/sprint/:id/evaluation"
                    component={Evaluation}
                  />
                  <PrivateRoute
                    exact
                    path="/sprint/review"
                    component={Review}
                  />
                  <PrivateRoute
                    exact
                    path="/coach/coaching/evaluations/:evaluationId"
                    component={CoachEvaluation}
                  />
                  <PrivateRoute
                    exact
                    path="/peers/evaluation/:evaluationId"
                    component={PeerEvaluation}
                  />
                  <PrivateRoute
                    exact
                    path="/coach/coachee/:coacheeId"
                    component={CoacheeDetailsPage}
                  />
                  <PrivateRoute
                    exact
                    path="/coach/coachee/:coacheeId/1on1/:id"
                    component={CoacheeOneOnOnePage}
                  />
                  <PrivateRoute exact path="/library" component={Library} />
                  <PrivateRoute
                    exact
                    path="/library/manage"
                    component={ManageLibrary}
                  />
                  <PrivateRoute
                    exact
                    path="/library/manage/create-path"
                    component={CreateLibraryPath}
                  />
                  <PrivateRoute exact path="/coach" component={CoachPage} />
                  <PrivateRoute exact path="/1on1" component={OneOnOnes} />
                  <PrivateRoute path="/admin" component={AdminWrapper} />
                  <PrivateRoute
                    exact
                    path="/slacksuccess"
                    component={SlackSuccess}
                  />
                  <PrivateRoute
                    exact
                    path="/search/:searchQuery?"
                    component={SearchPage}
                  />
                </Switch>
              </Suspense>
            </AuthProvider>
          </SnackbarProvider>
        </StoresContext.Provider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};

//Private route with authentication
const PrivateRoute = ({ component: Component, ...rest }) => (
  <AuthContext.Consumer>
    {({ getCurrentUser }) => (
      <Route
        {...rest}
        render={(props) =>
          window.localStorage.getItem("token") && getCurrentUser() ? (
            <WithNavigation {...props}>
              <Component {...props} />
            </WithNavigation>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )}
  </AuthContext.Consumer>
);

export default App;
