import { createContext, useContext } from "react";

//import stores
import { GeneralStore } from "./general";
import { UserStore } from "./user";
import { TeamStore } from "./team";
import { GoalsStore } from "./goals";
import { AdminStore } from "./admin";
import { CompanyStore } from "./company";
import { LibraryStore } from "./library";
import { FeedbackStore } from "./feedback";
import { CoachStore } from "./coach";

//init stores
export const generalStore = new GeneralStore();
export const userStore = new UserStore();
export const teamStore = new TeamStore();
export const goalsStore = new GoalsStore();
export const adminStore = new AdminStore();
export const companyStore = new CompanyStore();
export const libraryStore = new LibraryStore();
export const feedbackStore = new FeedbackStore();
export const coachStore = new CoachStore();

//Create context
export const StoresContext = createContext({
  generalStore,
  userStore,
  goalsStore,
  adminStore,
  teamStore,
  companyStore,
  libraryStore,
  feedbackStore,
  coachStore
});
export const useStores = () => useContext(StoresContext);
