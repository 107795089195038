import { makeObservable, observable, action } from "mobx";
import * as userService from "../services/userService";

export class UserStore {
  //User store observables
  firstLogin = false;
  currentUser = null;
  currentUserFeedback = null;
  currentRole = null;
  futureRole = null;
  userTeams = null;
  userLoading = false;
  feedbackSendLoading = false;
  settingsError = null;
  profileEditMode = false;
  tabIndex = 0;
  multipleUserLoading = false;
  userCertificates = [];
  userSkills = [];
  skillsToFocus = [];
  pendingEvaluations = [];
  evaluationDates = [];

  //User store actions

  setFirstLogin = (state) => {
    this.firstLogin = state;
  };

  getCurrentUser = async () => {
    this.userLoading = true;
    let data = await userService.getCurrentUser();
    this.currentUser = data.currentUser;
    this.currentUser.hasUserSlackIntegration = data.hasUserSlackIntegration;
    this.currentUser.companyHasSlackIntegration =
      data.companyHasSlackIntegration;
    this.getCurrentUserFeedback();
    this.userLoading = false;
    return data.currentUser.userProfile;
  };

  getCurrentUserFeedback = async () => {
    this.userLoading = true;
    let data = await userService.getUserFeedback(this.currentUser.id);
    this.currentUserFeedback = data.userPersonalComments;
    this.userLoading = false;
  };

  getPendingEvaluations = async () => {
    this.userLoading = true;
    let data = await userService.getPendingEvaluations();
    this.pendingEvaluations = data.pendingUserEvaluations;
    this.userLoading = false;
  };

  getEvaluationDates = async () => {
    this.userLoading = true;
    let data = await userService.getEvaluationDates();
    this.evaluationDates = data.userEvaluationDates;
    this.userLoading = false;
  };

  getOneOnOnes = async () => {
    this.userLoading = true;
    if (this.currentUser) {
      let data = await userService.getOneOnOnes(this.currentUser.id);
      this.currentUser.oneOnOnes = data.oneOnOnes;
      this.userLoading = false;
    }
  };

  setCurrentUser = async (auth) => {
    this.currentUser.company = auth.getCurrentUser().company;
    auth.setCurrentUser(this.currentUser);
  };

  setProfileEditMode = (mode) => {
    this.profileEditMode = mode;
  };

  setTabIndex = (mode) => {
    this.tabIndex = mode;
  };

  logOutCurrentUser = async (auth) => {
    this.currentUser = null;
    this.userTeams = null;
    this.pendingEvaluations = [];
    await userService.logout();
    auth.logout();
  };

  getUserTeams = async () => {
    this.userLoading = true;
    let data = await userService.getUserTeams();
    this.userTeams = data.userTeams;
    this.userLoading = false;
  };

  updateUserSettings = async (payload) => {
    //Update settings and get current user
    await userService.updateUserSettings(this.currentUser.id, payload);
    await this.getCurrentUser();
  };

  updateUserProfile = async (data) => {
    this.userLoading = true;
    const response = await userService.updateUserProfile(
      this.currentUser.id,
      data
    );
    await this.getCurrentUser();
    this.userLoading = false;
    return response.updateUserProfile.userProfile;
  };

  updateUserSkills = async (skillLevels) => {
    this.currentUser.userProfile.skillLevels = skillLevels.skillLevels;
  };

  updateUserImage = async (image) => {
    this.userLoading = true;
    await userService.uploadImage(image);
    await this.getCurrentUser();
    this.userLoading = false;
  };

  sendFeedback = async (data) => {
    this.feedbackSendLoading = true;
    await userService.createFeedback(
      this.currentUser.id,
      data.userId,
      data.feedback,
      data.isAnonymous
    );
    this.feedbackSendLoading = false;
  };

  removeSlackIntegration = async () => {
    this.userLoading = true;
    let data = await userService.deleteSlackIntegration();
    if (data.error) {
    }
    await this.getCurrentUser();
    this.userLoading = false;
  };

  setCurrentRole = async (role) => {
    this.currentRole = role;
  };

  setFutureRole = async (role) => {
    this.futureRole = role;
  };

  searchUserWithMultipleSkills = async (skills, includeSelf) => {
    this.multipleUserLoading = true;
    const data = await userService.searchUserWithMultipleSkills(
      skills,
      includeSelf
    );
    this.multipleUserLoading = false;
    return data;
  };

  setUserCertificates = (certificates) => {
    this.userCertificates = certificates;
  };

  removeCertificates = (certificate) => {
    this.userCertificates = this.userCertificates.filter(
      (cert) => cert.id !== certificate.id
    );
  };

  setUserSkills = (skills) => {
    this.userSkills = skills;
  };

  setFutureSkills = (skills) => {
    this.skillsToFocus = skills;
  };

  constructor() {
    makeObservable(this, {
      getCurrentUser: action,
      getCurrentUserFeedback: action,
      getPendingEvaluations: action,
      setCurrentUser: action,
      setCurrentRole: action,
      setFutureRole: action,
      logOutCurrentUser: action,
      sendFeedback: action,
      removeSlackIntegration: action,
      updateUserImage: action,
      updateUserProfile: action,
      updateUserSettings: action,
      getUserTeams: action,
      setProfileEditMode: action,
      setTabIndex: action,
      setFirstLogin: action,
      updateUserSkills: action,
      setUserCertificates: action,
      removeCertificates: action,
      searchUserWithMultipleSkills: action,
      setUserSkills: action,
      setFutureSkills: action,
      getEvaluationDates: action,
      firstLogin: observable,
      currentUser: observable,
      currentUserFeedback: observable,
      userLoading: observable,
      feedbackSendLoading: observable,
      userTeams: observable,
      profileEditMode: observable,
      tabIndex: observable,
      currentRole: observable,
      futureRole: observable,
      userCertificates: observable,
      userSkills: observable,
      skillsToFocus: observable,
      pendingEvaluations: observable,
      evaluationDates: observable,
    });
  }
}
