import { makeObservable, observable, action } from "mobx";
import * as adminService from "../services/adminService";

export class AdminStore {
  //Admin store observables
  companyLoading = false;
  companySettings = null;
  companyUsers = null;
  companyTeams = null;
  companyCareerPaths = null;
  companyLearningPaths = null;
  companyTags = null;
  settingsLoading = false;
  userInviteLoading = false;
  pendingInvites = [];

  //Admin store actions
  getCompanySettings = async () => {
    this.settingsLoading = true;
    let data = await adminService.getCompanySettings();
    this.companySettings = data;
    this.settingsLoading = false;
  };

  getPendingInvites = async () => {
    let data = await adminService.getPendingInvites();
    this.pendingInvites = data.userInvites;
  };

  getCompanyUsers = async () => {
    let data = await adminService.getCompanyUsers();
    this.companyUsers = data.companyUsers;
  };

  // Career and learning path actions
  getCompanyLearningPaths = async () => {
    let data = await adminService.getCompanyLearningPaths();
    this.companyLearningPaths = data.companyLearningPaths;
  };

  getCompanyCareerPaths = async () => {
    let data = await adminService.getCompanyCareerPaths();
    this.companyCareerPaths = data.companyCareerPaths;
  };

  getCompanyTags = async () => {
    let data = await adminService.getCompanyTags();
    this.companyTags = data.companyLearningPathTags;
  };

  createCompanyTag = async (input) => {
    this.companyLoading = true;
    let data = await adminService.createCompanyTag(input);
    this.companyLoading = false;
    return data;
  };

  updateCompanyTag = async (input, tagId) => {
    this.companyLoading = true;
    let data = await adminService.updateCompanyTag(input, tagId);
    this.companyLoading = false;
    return data;
  };

  deleteCompanyTag = async (tagId) => {
    this.companyLoading = true;
    let data = await adminService.deleteCompanyTag(tagId);
    this.companyLoading = false;
    return data;
  };

  deleteCompanyLearningPath = async (goalId) => {
    this.companyLoading = true;
    let data = await adminService.deleteCompanyLearningPath(goalId);
    this.companyLoading = false;
    return data;
  };

  createCompanyLearningPath = async (input) => {
    this.companyLoading = true;
    let data = await adminService.createCompanyLearningPath(input);
    this.companyLoading = false;
    return data;
  };

  updateCompanyLearningPath = async (input, pathId) => {
    this.companyLoading = true;
    let data = await adminService.updateCompanyLearningPath(input, pathId);
    this.companyLoading = false;
    return data;
  };

  createCompanyCareerPath = async (input) => {
    this.companyLoading = true;
    let data = await adminService.createCompanyCareerPath(input);
    this.companyLoading = false;
    return data;
  };

  updateCompanyCareerPath = async (input, pathId) => {
    this.companyLoading = true;
    let data = await adminService.updateCompanyCareerPath(input, pathId);
    this.companyLoading = false;
    return data;
  };

  deleteCompanyCareerPath = async (pathId) => {
    this.companyLoading = true;
    let data = await adminService.deleteCompanyCareerPath(pathId);
    this.companyLoading = false;
    return data;
  };

  getCompanyTeams = async () => {
    let data = await adminService.getCompanyTeams();
    this.companyTeams = data.teams;
  };

  // User invite related actions
  inviteUsers = async (emails) => {
    this.userInviteLoading = true;
    const pendingUser = await Promise.all(
      emails.map(async (email) => {
        const user = await adminService.sendInvite(email);
        return user;
      })
    );
    this.pendingInvites = [...this.pendingInvites, ...pendingUser];
    this.userInviteLoading = false;
  };

  inviteTeamUsers = async (emails, teamId) => {
    this.userInviteLoading = true;
    emails.map(async (email) => {
      await adminService.sendTeamInvite(email, teamId);
    });
    this.userInviteLoading = false;
  };

  resendInvite = async (email) => {
    this.userInviteLoading = true;
    let data = await adminService.resendInvite(email);
    this.userInviteLoading = false;
    return data;
  };

  constructor() {
    makeObservable(this, {
      companySettings: observable,
      companyUsers: observable,
      companyTeams: observable,
      companyLoading: observable,
      settingsLoading: observable,
      userInviteLoading: observable,
      pendingInvites: observable,
      companyCareerPaths: observable,
      companyLearningPaths: observable,
      companyTags: observable,
      getCompanyTags: action,
      createCompanyTag: action,
      updateCompanyTag: action,
      deleteCompanyTag: action,
      getCompanyLearningPaths: action,
      getCompanyCareerPaths: action,
      createCompanyLearningPath: action,
      deleteCompanyLearningPath: action,
      updateCompanyLearningPath: action,
      createCompanyCareerPath: action,
      deleteCompanyCareerPath: action,
      getCompanySettings: action,
      getCompanyUsers: action,
      getCompanyTeams: action,
      inviteUsers: action,
      getPendingInvites: action,
    });
  }
}
