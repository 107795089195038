import client from "../client";
import { gql } from "@apollo/client";

/*  Feedback related queries and mutations */

const GET_FEEDBACK_TEMPLATES = gql`
  query {
    feedbackFormTemplates {
      id
      title {
        fi
        en
      }
      description {
        fi
        en
      }
      questions {
        max
        min
        question {
          en
          fi
        }
        type
        sliderQuestionValues {
          question {
            en
            fi
          }
          value
        }
      }
      requests
      links
    }
  }
`;

const GET_USER_FEEDBACK_FORMS = gql`
  query ($from: Date, $to: Date) {
    userFeedbackForms(from: $from, to: $to) {
      title
      description
      id
      expiration
      token
      organizationName
      insertedAt
      user {
        id
        userProfile {
          id
          name
        }
      }
      team {
        id
        name
      }
      skill {
        name
        skillCategory {
          name
        }
      }
      task {
        name
        description
      }
      questions {
        id
        max
        min
        question {
          en
          fi
        }
        sliderQuestionValues {
          question {
            en
            fi
          }
          value
        }
        type
        answers {
          value {
            integer
            text
          }
          insertedAt
        }
      }
    }
  }
`;

const GET_FEEDBACK_FORM = gql`
  query ($token: String!) {
    feedbackForm(token: $token) {
      title
      description
      id
      expiration
      token
      organizationName
      user {
        id
        userProfile {
          id
          name
        }
      }
      team {
        id
        name
      }
      creator {
        userProfile {
          name
          photoUrl
        }
      }
      skill {
        name
        skillCategory {
          name
        }
      }
      task {
        name
        description
      }
      questions {
        id
        max
        min
        question {
          en
          fi
        }
        type
      }
    }
  }
`;

const GET_PERSONAL_COMMENTS = gql`
  query UserPersonalComments($userId: ID!, $from: Date, $to: Date) {
    userPersonalComments(userId: $userId, from: $from, to: $to) {
      id
      comment
      from {
        name
        id
        photoUrl
      }
    }
  }
`;

const GET_PENDING_FEEDBACK_FORMS = gql`
  query ($userId: ID!) {
    pendingUserFeedbackForms(userId: $userId) {
      id
      hasSeen
      insertedAt
      feedbackForm {
        creator {
          userProfile {
            name
            photoUrl
          }
        }
        title
        description
        id
        expiration
        token
        skill {
          name
          skillCategory {
            name
          }
        }
        task {
          name
          description
        }
        questions {
          id
          max
          min
          question {
            en
            fi
          }
          sliderQuestionValues {
            question {
              en
              fi
            }
            value
          }
          type
        }
      }
    }
  }
`;

const CREATE_FEEDBACK_FORM = gql`
  mutation ($input: FeedbackFormInput!) {
    createFeedbackForm(input: $input) {
      errors {
        key
        message
      }
      feedbackForm {
        id
      }
    }
  }
`;

const CREATE_FEEDBACK_FORM_TEMPLATE = gql`
  mutation ($input: FeedbackFormTemplateInput!) {
    createCompanyFeedbackFormTemplate(input: $input) {
      errors {
        key
        message
      }
      feedbackFormTemplate {
        id
        title {
          fi
          en
        }
        description {
          fi
          en
        }
        questions {
          max
          min
          question {
            en
            fi
          }
          type
          sliderQuestionValues {
            question {
              en
              fi
            }
            value
          }
        }
        requests
        links
      }
    }
  }
`;

const CREATE_FEEDBACK_FORM_ANSWERS = gql`
  mutation ($token: String!, $input: FeedbackFormAnswersInput!) {
    createFeedbackFormAnswers(token: $token, input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

const SEND_REQUEST_TO_USER = gql`
  mutation ($userId: ID!, $feedbackFormId: ID!) {
    sendFeedbackRequestToUser(
      userId: $userId
      feedbackFormId: $feedbackFormId
    ) {
      errors {
        key
        message
      }
    }
  }
`;

const SEND_REQUEST_TO_USERS = gql`
  mutation ($userIds: [ID], $feedbackFormId: ID!) {
    sendFeedbackRequestToMultipleUsers(
      userIds: $userIds
      feedbackFormId: $feedbackFormId
    ) {
      errors {
        key
        message
      }
    }
  }
`;

const SEND_REQUEST_TO_TEAM = gql`
  mutation ($teamId: ID!, $feedbackFormId: ID!) {
    sendFeedbackRequestToTeam(
      teamId: $teamId
      feedbackFormId: $feedbackFormId
    ) {
      errors {
        key
        message
      }
    }
  }
`;

const SEND_REQUEST_TO_COMPANY = gql`
  mutation ($feedbackFormId: ID!) {
    sendFeedbackRequestToCompany(feedbackFormId: $feedbackFormId) {
      errors {
        key
        message
      }
    }
  }
`;

const UPDATE_PENDING_FEEDBACK_FORM = gql`
  mutation ($pendingFeedbackFormId: ID!, $input: PendingFeedbackFormInput!) {
    updatePendingFeedbackForm(
      pendingFeedbackFormId: $pendingFeedbackFormId
      input: $input
    ) {
      errors {
        key
        message
      }
    }
  }
`;

const UPDATE_FEEDBACK_FORM_TEMPLATE = gql`
  mutation ($input: FeedbackFormTemplateInput!, $feedbackFormTemplateId: ID!) {
    updateCompanyFeedbackFormTemplate(
      input: $input
      feedbackFormTemplateId: $feedbackFormTemplateId
    ) {
      errors {
        key
        message
      }
      feedbackFormTemplate {
        id
        title {
          fi
          en
        }
        description {
          fi
          en
        }
        questions {
          max
          min
          question {
            en
            fi
          }
          type
          sliderQuestionValues {
            question {
              en
              fi
            }
            value
          }
        }
        requests
        links
      }
    }
  }
`;

const DELETE_FEEDBACK_FORM_TEMPLATE = gql`
  mutation ($feedbackFormTemplateId: ID!) {
    deleteCompanyFeedbackFormTemplate(
      feedbackFormTemplateId: $feedbackFormTemplateId
    ) {
      errors {
        key
        message
      }
      feedbackFormTemplate {
        id
        title {
          fi
          en
        }
        description {
          fi
          en
        }
        questions {
          max
          min
          question {
            en
            fi
          }
          type
          sliderQuestionValues {
            question {
              en
              fi
            }
            value
          }
        }
        requests
        links
      }
    }
  }
`;

const GET_COMPANY_FEEDBACK_TEMPLATES = gql`
  query {
    companyFeedbackFormTemplates {
      id
      title {
        fi
        en
      }
      description {
        fi
        en
      }
      questions {
        max
        min
        question {
          en
          fi
        }
        type
        sliderQuestionValues {
          question {
            en
            fi
          }
          value
        }
      }
      requests
      links
    }
  }
`;

/* Actions for fetching and mutating data */

export const getFeedbackTemplates = async () => {
  const res = await client.query({
    query: GET_FEEDBACK_TEMPLATES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getUserFeedbackForms = async () => {
  const res = await client.query({
    query: GET_USER_FEEDBACK_FORMS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getUserFeedbackFormsByDate = async (from, to) => {
  const res = await client.query({
    query: GET_USER_FEEDBACK_FORMS,
    variables: {
      from: from,
      to: to,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getFeedbackForm = async (token) => {
  const res = await client.query({
    query: GET_FEEDBACK_FORM,
    variables: {
      token: token,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getPersonalComments = async (userId) => {
  const res = await client.query({
    query: GET_PERSONAL_COMMENTS,
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getPersonalCommentsByDate = async (userId, from, to) => {
  const res = await client.query({
    query: GET_PERSONAL_COMMENTS,
    variables: {
      userId: userId,
      from: from,
      to: to,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getPendingFeedbackForms = async (userId) => {
  const res = await client.query({
    query: GET_PENDING_FEEDBACK_FORMS,
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createFeedbackFormAnswers = async (token, input) => {
  const res = await client.mutate({
    mutation: CREATE_FEEDBACK_FORM_ANSWERS,
    variables: {
      token: token,
      input: input,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createFeedbackForm = async (input) => {
  const res = await client.mutate({
    mutation: CREATE_FEEDBACK_FORM,
    variables: {
      input: input,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createFeedbackFormTemplate = async (input) => {
  const res = await client.mutate({
    mutation: CREATE_FEEDBACK_FORM_TEMPLATE,
    variables: {
      input: input,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const sendFeedbackRequestToUser = async (formId, userId) => {
  const res = await client.mutate({
    mutation: SEND_REQUEST_TO_USER,
    variables: {
      userId: userId,
      feedbackFormId: formId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const sendFeedbackRequestToUsers = async (formId, userIds) => {
  const res = await client.mutate({
    mutation: SEND_REQUEST_TO_USERS,
    variables: {
      userIds: userIds,
      feedbackFormId: formId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const sendFeedbackRequestToTeam = async (formId, teamId) => {
  const res = await client.mutate({
    mutation: SEND_REQUEST_TO_TEAM,
    variables: {
      teamId: teamId,
      feedbackFormId: formId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const sendFeedbackRequestToCompany = async (formId) => {
  const res = await client.mutate({
    mutation: SEND_REQUEST_TO_COMPANY,
    variables: {
      feedbackFormId: formId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updatePendingFeedbackForm = async (pendingFormId, input) => {
  const res = await client.mutate({
    mutation: UPDATE_PENDING_FEEDBACK_FORM,
    variables: {
      pendingFeedbackFormId: pendingFormId,
      input: input,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateFeedbackFormTemplate = async (id, input) => {
  const res = await client.mutate({
    mutation: UPDATE_FEEDBACK_FORM_TEMPLATE,
    variables: {
      input: input,
      feedbackFormTemplateId: id,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const deleteFeedbackFormTemplate = async (id, input) => {
  const res = await client.mutate({
    mutation: DELETE_FEEDBACK_FORM_TEMPLATE,
    variables: {
      input: input,
      feedbackFormTemplateId: id,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyFeedbackTemplates = async () => {
  const res = await client.query({
    query: GET_COMPANY_FEEDBACK_TEMPLATES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};
