import { makeObservable, observable, action } from "mobx";
import * as feedbackService from "../services/feedbackService";

export class FeedbackStore {
  personalComments = null;
  feedbackTemplates = null;
  feedbackForms = null;
  pendingForms = null;
  selectedTemplate = null;
  selectedForm = null;
  templateDialogOpen = false;
  feedbackLoading = false;
  formAnswerMode = false;
  tabIndex = 0;
  sprintReviewQuestions = [];
  coachSprintFeedback = null;
  companyFeedbackTemplates = null;

  getFeedbackTemplates = async () => {
    this.feedbackLoading = true;
    let data = await feedbackService.getFeedbackTemplates();
    let companyFeedbacks = await feedbackService.getCompanyFeedbackTemplates();

    companyFeedbacks &&
      companyFeedbacks.companyFeedbackFormTemplates &&
      companyFeedbacks.companyFeedbackFormTemplates.forEach((template) => {
        template.id = "C_" + template.id;
      });

    this.feedbackTemplates = [
      ...data.feedbackFormTemplates,
      ...companyFeedbacks.companyFeedbackFormTemplates,
    ];
    this.feedbackLoading = false;
  };

  getCompanyFeedbackTemplates = async () => {
    this.feedbackLoading = true;
    let data = await feedbackService.getCompanyFeedbackTemplates();
    this.companyFeedbackTemplates = data.companyFeedbackFormTemplates;
    this.feedbackLoading = false;
  };

  getFeedbackForms = async () => {
    this.feedbackLoading = true;
    let data = await feedbackService.getUserFeedbackForms();

    data.userFeedbackForms.sort((a, b) =>
      b.insertedAt.localeCompare(a.insertedAt)
    );
    this.feedbackForms = data.userFeedbackForms;
    this.feedbackLoading = false;
  };

  getFeedbackFormsByDate = async (from, to) => {
    this.feedbackLoading = true;
    let data = await feedbackService.getUserFeedbackFormsByDate(from, to);
    this.feedbackForms = data.userFeedbackForms;
    this.feedbackLoading = false;
  };

  getFeedbackForm = async (token) => {
    this.feedbackLoading = true;
    await feedbackService.getFeedbackForm(token).then((data) => {
      this.selectedForm = data.feedbackForm;
    });
    this.feedbackLoading = false;
  };

  getPendingFeedbackForms = async (userId) => {
    this.feedbackLoading = true;
    let data = await feedbackService.getPendingFeedbackForms(userId);

    data.pendingUserFeedbackForms.sort((a, b) =>
      b.insertedAt.localeCompare(a.insertedAt)
    );

    this.pendingForms = data.pendingUserFeedbackForms;
    this.feedbackLoading = false;
  };

  getPersonalComments = async (userId, from, to) => {
    this.feedbackLoading = true;
    let data;
    if (from && to) {
      data = await feedbackService.getPersonalCommentsByDate(userId, from, to);
    } else {
      data = await feedbackService.getPersonalComments(userId);
    }
    this.personalComments = data.userPersonalComments;
    this.feedbackLoading = false;
  };

  createAndSendFeedbackTemplate = async (data) => {
    this.feedbackLoading = true;
    let input = {};
    input.title =
      data.lang === "en"
        ? this.selectedTemplate.title.en
        : this.selectedTemplate.title.fi;
    input.description =
      data.lang === "en"
        ? this.selectedTemplate.description.en
        : this.selectedTemplate.description.fi;
    input.questions = this.selectedTemplate.questions;

    //Omit those typenames
    const omitTypename = (key, value) =>
      key === "__typename" ? undefined : value;
    input.questions = JSON.parse(JSON.stringify(input.questions), omitTypename);

    //Check if linked skills or milestones
    if (data.linkedSkill) {
      input.skillId = data.linkedSkill.id;
    }
    if (data.linkedMilestone) {
      input.taskId = data.linkedMilestone.id;
    }

    await feedbackService.createFeedbackForm(input).then((res) => {
      let formId = res.createFeedbackForm.feedbackForm.id;
      if (data.sendToPersons) {
        const userIds = [];
        data.sendToPersons.map((person) => userIds.push(person.user_id));
        feedbackService.sendFeedbackRequestToUsers(formId, userIds);
      } else if (data.sendToTeam) {
        feedbackService.sendFeedbackRequestToTeam(formId, data.sendToTeam);
      } else if (data.sendToCompany) {
        feedbackService.sendFeedbackRequestToCompany(formId);
      }
    });
    this.feedbackLoading = false;
  };

  createFeedbackTemplate = async (template) => {
    this.feedbackLoading = true;
    await feedbackService.createFeedbackFormTemplate(template).then((data) => {
      if (
        data.createCompanyFeedbackFormTemplate &&
        data.createCompanyFeedbackFormTemplate.feedbackFormTemplate
      ) {
        this.companyFeedbackTemplates.push(
          data.createCompanyFeedbackFormTemplate.feedbackFormTemplate
        );

        this.companyFeedbackTemplates = [...this.companyFeedbackTemplates];
      }
    });
    this.feedbackLoading = false;
  };

  createFeedbackFormAnswers = (answers, token) => {
    this.feedbackLoading = true;
    let res = feedbackService.createFeedbackFormAnswers(token, {
      answers: answers,
    });
    this.feedbackLoading = false;
    return res;
  };

  updatePendingFeedbackForm = async (pendingFormId, input) => {
    await feedbackService.updatePendingFeedbackForm(pendingFormId, input);
    this.getPendingFeedbackForms();
  };

  updateFeedbackTemplate = async (id, template) => {
    this.feedbackLoading = true;
    await feedbackService
      .updateFeedbackFormTemplate(id, template)
      .then((data) => {
        if (
          data.updateCompanyFeedbackFormTemplate &&
          data.updateCompanyFeedbackFormTemplate.feedbackFormTemplate
        ) {
          let updated =
            data.updateCompanyFeedbackFormTemplate.feedbackFormTemplate;
          this.companyFeedbackTemplates.forEach((template) => {
            if (template.id === updated.id) {
              template.description = updated.description;
              template.links = updated.links;
              template.questions = updated.questions;
              template.requests = updated.requests;
              template.title = updated.title;
            }
          });
          this.companyFeedbackTemplates = [...this.companyFeedbackTemplates];
        }
      });
    this.feedbackLoading = false;
  };

  deleteFeedbackTemplate = async (id) => {
    this.feedbackLoading = true;
    await feedbackService.deleteFeedbackFormTemplate(id).then((data) => {
      if (
        data.deleteCompanyFeedbackFormTemplate &&
        data.deleteCompanyFeedbackFormTemplate.feedbackFormTemplate
      ) {
        const filtered = this.companyFeedbackTemplates.filter(
          (template) => template.id !== id
        );
        this.companyFeedbackTemplates = [...filtered];
      }
    });
    this.feedbackLoading = false;
  };

  selectTemplate = (templateId) => {
    this.selectedTemplate = this.feedbackTemplates.find(
      (template) => template.id === templateId
    );
  };

  selectForm = (formId) => {
    this.selectedForm = this.feedbackForms.find((form) => form.id === formId);
  };

  selectPendingForm = (formId) => {
    let selectedForm = this.pendingForms.find(
      (form) => form.feedbackForm.id === formId
    );
    this.selectedForm = selectedForm.feedbackForm;
  };

  clearTemplate = () => {
    this.selectedTemplate = null;
  };

  setDialogState = (boolean) => {
    this.templateDialogOpen = boolean;
  };

  setFormAnswerMode = (boolean) => {
    this.formAnswerMode = boolean;
  };

  setTabIndex = (tabIndex) => {
    this.tabIndex = tabIndex;
  };

  constructor() {
    makeObservable(this, {
      feedbackTemplates: observable,
      feedbackForms: observable,
      feedbackLoading: observable,
      pendingForms: observable,
      templateDialogOpen: observable,
      selectedTemplate: observable,
      selectedForm: observable,
      formAnswerMode: observable,
      personalComments: observable,
      tabIndex: observable,
      sprintReviewQuestions: observable,
      coachSprintFeedback: observable,
      companyFeedbackTemplates: observable,
      getFeedbackTemplates: action,
      getCompanyFeedbackTemplates: action,
      getPendingFeedbackForms: action,
      selectTemplate: action,
      clearTemplate: action,
      createAndSendFeedbackTemplate: action,
      getFeedbackForms: action,
      getFeedbackForm: action,
      selectForm: action,
      selectPendingForm: action,
      setDialogState: action,
      setFormAnswerMode: action,
      createFeedbackFormAnswers: action,
      getPersonalComments: action,
      getFeedbackFormsByDate: action,
      setTabIndex: action,
      updatePendingFeedbackForm: action,
      deleteFeedbackTemplate: action,
      updateFeedbackTemplate: action,
      createFeedbackTemplate: action,
    });
  }
}
