import client from "../client";
import { gql } from "@apollo/client";

/*  Library related queries and mutations */

const GET_LEARNING_PATHS = gql`
  query {
    publicLearningPaths {
      id
      description {
        fi
        en
      }
      type
      imageUrl
      name {
        fi
        en
      }
      skills {
        skill {
          id
          name
          description
          skillCategory {
            id
            key
            name
          }
        }
      }
      tasks {
        id
        name {
          fi
          en
        }
        description {
          fi
          en
        }
        order
      }
      points
      tags {
        id
        name {
          fi
          en
        }
        description {
          fi
          en
        }
      }
    }
  }
`;

const CREATE_PATH_TO_DEV_PATH = gql`
  mutation($lang: String!, $learningPathId: ID!) {
    createPathFromPublicLearningPath(
      lang: $lang
      learningPathId: $learningPathId
    ) {
      errors {
        key
        message
      }
      goal {
        id
        name
        description
        dueDate
        type
        isPrivate
        points
        skills {
          skill {
            id
            name
            skillCategory {
              name
            }
          }
        }
        tasks {
          id
          inserted_at
          name
          comment
          completed
          description
          feedback_url
          nps
          score
          goal {
            id
            name
          }
          feedback {
            task_feedback_question_id
            answer
            question
          }
        }
        companyObjective {
          id
          name
          description
          dueDate
        }
        teamObjective {
          id
          name
          description
          dueDate
        }
      }
    }
  }
`;

const CREATE_COMP_LEARN_PATH_TO_DEV_PATH = gql`
  mutation($lang: String!, $companyLearningPathId: ID!) {
    createPathFromCompanyLearningPath(
      lang: $lang
      companyLearningPathId: $companyLearningPathId
    ) {
      errors {
        key
        message
      }
      goal {
        id
        name
        description
        dueDate
        type
        isPrivate
        points
        skills {
          skill {
            id
            name
            skillCategory {
              name
            }
          }
        }
        tasks {
          id
          inserted_at
          name
          comment
          completed
          description
          feedback_url
          nps
          score
          goal {
            id
            name
          }
          feedback {
            task_feedback_question_id
            answer
            question
          }
        }
        companyObjective {
          id
          name
          description
          dueDate
        }
        teamObjective {
          id
          name
          description
          dueDate
        }
      }
    }
  }
`;

const CREATE_CAREER_PATH_TO_DEV_PATH = gql`
  mutation($lang: String!, $companyCareerPathId: ID!) {
    createPathFromCompanyCareerPath(
      lang: $lang
      companyCareerPathId: $companyCareerPathId
    ) {
      errors {
        key
        message
      }
      goal {
        id
        name
        description
        dueDate
        type
        isPrivate
        points
        skills {
          skill {
            id
            name
            skillCategory {
              name
            }
          }
        }
        tasks {
          id
          inserted_at
          name
          comment
          completed
          description
          feedback_url
          nps
          score
          goal {
            id
            name
          }
          feedback {
            task_feedback_question_id
            answer
            question
          }
        }
        companyLearningPathId
        companyCareerPathId
        companyObjective {
          id
          name
          description
          dueDate
        }
        teamObjective {
          id
          name
          description
          dueDate
        }
      }
    }
  }
`;

/* Actions for fetching and mutating data */

export const getPublicLearningPaths = async () => {
  const res = await client.query({
    query: GET_LEARNING_PATHS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createPathToDevPath = async (pathId, userLang) => {
  const res = await client.mutate({
    mutation: CREATE_PATH_TO_DEV_PATH,
    variables: {
      learningPathId: pathId,
      lang: userLang,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createCompanyPathToDevPath = async (pathId, userLang) => {
  const res = await client.mutate({
    mutation: CREATE_COMP_LEARN_PATH_TO_DEV_PATH,
    variables: {
      companyLearningPathId: pathId,
      lang: userLang,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createCareerPathToDevPath = async (pathId, userLang) => {
  const res = await client.mutate({
    mutation: CREATE_CAREER_PATH_TO_DEV_PATH,
    variables: {
      companyCareerPathId: pathId,
      lang: userLang,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};
