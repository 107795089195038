import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

//I18n setup based on currentUser language

const backendOpts = {
  loadPath: `${
    process.env.REACT_APP_API_URL || ""
  }/api/locales/{{lng}}/{{ns}}.json`,
};

let userLang = "en";
const currentUser = JSON.parse(window.localStorage.getItem("currentUser"));

if (currentUser && currentUser.userSettings && currentUser.userSettings.lang) {
  userLang = currentUser.userSettings.lang;
}

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: backendOpts,
    lng: userLang,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
