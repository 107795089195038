import client from "../client";
import { gql } from "@apollo/client";

/*  Coach view related queries and mutations */

const GET_COACHEES = gql`
  query {
    coachees {
      name
      id
      userId
      photoUrl
      title
    }
  }
`;

const GET_COACHEE_MEMOS = gql`
  query ($userId: ID!) {
    coachUserMemos(userId: $userId) {
      id
      memo
    }
  }
`;

const CREATE_COACH_MEMO = gql`
  mutation ($input: CoachMemoInput!) {
    createCoachMemo(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

const DELETE_COACH_MEMO = gql`
  mutation ($coachMemoId: ID!) {
    deleteCoachMemo(coachMemoId: $coachMemoId) {
      errors {
        key
        message
      }
    }
  }
`;

const GET_SPRINTS = gql`
  query ($userId: ID!) {
    userDevelopmentSprints(userId: $userId) {
      id
      from
      to
      coach {
        id
        userProfile {
          name
          photoUrl
          title
        }
      }
      hrPerson {
        id
        userProfile {
          name
          photoUrl
          title
        }
      }
      peers {
        id
        userProfile {
          name
          photoUrl
        }
      }
      questions {
        id
        max
        min
        answers {
          developmentSprintReviewQuestionId
          id
          value {
            text
            integer
          }
        }
        question {
          en
          fi
          se
        }
        responder {
          id
          userProfile {
            name
          }
        }
        sliderQuestionValues {
          question {
            en
            fi
          }
          value
        }
        type
      }
      status
      checkpoints {
        id
        date
        oneOnOneHeld
        coach {
          id
          userProfile {
            id
            name
            photoUrl
            title
          }
        }
        questions {
          id
          max
          min
          question {
            en
            fi
            se
          }
          answers {
            developmentSprintCheckpointQuestionId
            id
            insertedAt
            value {
              text
              integer
            }
          }
          responder {
            id
            userProfile {
              name
            }
          }
          sliderQuestionValues {
            question {
              en
              fi
            }
            value
          }
          type
        }
      }
      goals {
        id
        name
        completed
        description
        dueDate
        type
        isPrivate
        points
        skills {
          skill {
            id
            name
            skillCategory {
              name
            }
          }
        }
        tasks {
          id
          inserted_at
          name
          comment
          completed
          completionPercentage
          description
          feedback_url
          nps
          score
          state
          goal {
            type
            name
          }
          feedback {
            task_feedback_question_id
            answer
            question
          }
        }
        companyObjective {
          id
          name
          description
          dueDate
        }
        teamObjective {
          id
          name
          description
          dueDate
          companyObjective {
            id
            name
            description
            dueDate
          }
        }
      }
    }
  }
`;

const GET_USER_SLACK_ANSWER_DATA = gql`
  query ($userId: ID!) {
    userSlackAnswerData(userId: $userId) {
      first
      second
      third {
        insertedAt
        integer
        text
      }
      fourth {
        insertedAt
        integer
        text
      }
    }
  }
`;
/* Actions for fetching and mutating data */

export const getCoachees = async () => {
  const res = await client.query({
    query: GET_COACHEES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCoacheeSprints = async (userId) => {
  const res = await client.query({
    query: GET_SPRINTS,
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createCoachMemo = async (input) => {
  const res = await client.mutate({
    mutation: CREATE_COACH_MEMO,
    variables: {
      input: input,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const deleteCoachMemo = async (memoId) => {
  const res = await client.mutate({
    mutation: DELETE_COACH_MEMO,
    variables: {
      coachMemoId: memoId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCoachUserMemos = async (userId) => {
  const res = await client.query({
    query: GET_COACHEE_MEMOS,
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getUserSlackAnswerData = async (userId) => {
  const res = await client.query({
    query: GET_USER_SLACK_ANSWER_DATA,
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};
