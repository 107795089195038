import React from "react";
import ReactDOM from "react-dom";
import i18n from "./i18n/i18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

// Apollo client for GQL
import { ApolloProvider } from "@apollo/client";
import client from "./client";

// Import Stripe provider
import { loadStripe } from "@stripe/stripe-js";

//Import Boostrap & Base Talbit CSS
import "bootstrap/dist/css/bootstrap.css";
import "./styles/base.css";

//React datepicker CSS
import "./styles/widgets/react-datepicker-talbit.css";

let stripePromise;
if (process.env.NODE_ENV === "production") {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_LIVE);
} else {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
