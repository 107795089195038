import client from "../client";
import { gql } from "@apollo/client";

/*  Company related queries and mutations */

const GET_COMPANY_OBJECTIVES = gql`
  query companyObjectives {
    companyObjectives {
      description
      dueDate
      id
      name
      type
      skills {
        skill {
          id
          name
          description
          skillCategory {
            name
            key
            id
            parentCategory {
              id
              name
              key
            }
          }
        }
      }
      owner {
        id
        userProfile {
          name
          photoUrl
          id
        }
      }
      goals {
        id
        name
        completed
        dueDate
        tasks {
          id
          completed
          state
          completionPercentage
        }
        user {
          id
          userProfile {
            id
            name
          }
        }
      }
      keyResults {
        id
        title
        completed
        description
        dueDate
        evaluation
        initialValue
        targetValue
        learnings
        progress
        unit
        type
        score
        teamObjectives {
          id
          dueDate
          name
          description
          skills {
            skill {
              id
              name
              description
              skillCategory {
                id
                key
                name
                parentCategory {
                  id
                  name
                  key
                }
              }
            }
          }
        }
        companyObjective {
          id
          dueDate
          name
          skills {
            skill {
              id
              name
              description
              skillCategory {
                id
                key
                name
              }
            }
          }
        }
        owner {
          id
          userProfile {
            name
            photoUrl
          }
        }
        goals {
          id
          name
          completed
          dueDate
          tasks {
            id
            completed
            state
            completionPercentage
            name
          }
          user {
            id
            userProfile {
              id
              name
            }
          }
        }
        teamObjectives {
          id
          name
          keyResults {
            id
            title
            completed
            description
            dueDate
            evaluation
            initialValue
            targetValue
            learnings
            progress
            unit
            type
            teamObjective {
              name
            }
            goals {
              id
              name
              completed
              dueDate
              tasks {
                id
                completed
                state
                completionPercentage
                name
              }
              user {
                id
                userProfile {
                  id
                  name
                }
              }
            }
          }
          startDate
          team {
            name
            id
          }
          description
          dueDate
          companyObjectiveKeyResult {
            title
            description
            progress
            initialValue
            targetValue
            unit
          }
        }
      }
      teamObjectives {
        id
        dueDate
        name
        description
        skills {
          skill {
            id
            name
            description
            skillCategory {
              name
              id
            }
          }
        }
        team {
          id
          name
        }
        goals {
          id
          name
          completed
          dueDate
          tasks {
            id
            completed
            completionPercentage
          }
          user {
            id
            userProfile {
              id
              name
            }
          }
        }
      }
    }
  }
`;

//Create company objective mutations

const CREATE_COMPANY_OBJECTIVE = gql`
  mutation ($objective: CompanyObjectiveInput!) {
    createCompanyObjective(input: $objective) {
      errors {
        key
        message
      }
      companyObjective {
        id
        name
        description
        dueDate
      }
    }
  }
`;

const UPDATE_COMPANY_OBJECTIVE = gql`
  mutation ($companyObjectiveId: ID!, $objective: CompanyObjectiveInput!) {
    updateCompanyObjective(
      input: $objective
      companyObjectiveId: $companyObjectiveId
    ) {
      errors {
        key
        message
      }
      companyObjective {
        id
      }
    }
  }
`;

const DELETE_COMPANY_OBJECTIVE = gql`
  mutation ($companyObjectiveId: ID!) {
    deleteCompanyObjective(companyObjectiveId: $companyObjectiveId) {
      errors {
        key
        message
      }
    }
  }
`;

// Create key result mutations

const CREATE_COMPANY_OBJECTIVE_KEY_RESULT = gql`
  mutation ($input: CompanyObjectiveKeyResultInput!) {
    createCompanyObjectiveKeyResult(input: $input) {
      errors {
        key
        message
      }
      companyObjectiveKeyResult {
        id
      }
    }
  }
`;

const UPDATE_COMPANY_OBJECTIVE_KEY_RESULT = gql`
  mutation (
    $companyObjectiveKeyResultId: ID!
    $input: CompanyObjectiveKeyResultInput!
  ) {
    updateCompanyObjectiveKeyResult(
      input: $input
      companyObjectiveKeyResultId: $companyObjectiveKeyResultId
    ) {
      errors {
        key
        message
      }
      companyObjectiveKeyResult {
        id
      }
    }
  }
`;

const DELETE_COMPANY_OBJECTIVE_KEY_RESULT = gql`
  mutation ($companyObjectiveKeyResultId: ID!) {
    deleteCompanyObjectiveKeyResult(
      companyObjectiveKeyResultId: $companyObjectiveKeyResultId
    ) {
      errors {
        key
        message
      }
    }
  }
`;

const GET_SKILLS_UNDER_DEVELOPMENT = gql`
  query {
    skillsUnderDevelopment {
      skill {
        id
        name
        description
        skillCategory {
          id
          key
          name
        }
      }
      companyObjectives {
        description
        dueDate
        id
        name
        type
      }
      teamObjectives {
        id
        dueDate
        name
        description
        team {
          id
          name
        }
      }
      goals {
        id
        name
        completed
        dueDate
        isPrivate
        developmentSprints {
          id
          status
        }
        skills {
          skill {
            id
            name
            description
            skillCategory {
              id
              name
            }
          }
        }
        tasks {
          id
          completed
          completionPercentage
          name
        }
        type
        user {
          id
          userProfile {
            id
            name
            photoUrl
          }
        }
      }
      users {
        id
        userProfile {
          id
          name
        }
      }
    }
  }
`;

const GET_ONE_ON_ONES = gql`
  query allOneOnOnes($year: Int!) {
    allOneOnOnes(year: $year) {
      id
      date
      oneOnOneHeld
      coach {
        userProfile {
          id
          name
          photoUrl
        }
      }
      user {
        userProfile {
          id
          name
          photoUrl
        }
      }
    }
  }
`;
const GET_COMPANY_USERS_WITH_SKILLS = gql`
  query CompanyUsers {
    companyUsers {
      id
      userId
      photoUrl
      name
      roles
      skillsToFocus {
        skill {
          id
          name
          description
          skillCategory {
            id
            key
            name
            parentCategory {
              id
              name
            }
          }
        }
      }
      skillLevels {
        id
        skill {
          id
          name
          description
          skillCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
        }
        category
        level
        verifiedLevel
        wantToWorkWith
      }
    }
  }
`;

const GET_DEVELOPMENT_SPRINTS_DATA = gql`
  query DevelopmentSprintData($from: Date, $to: Date) {
    developmentSprintData(from: $from, to: $to) {
      employeesMissingSprint {
        id
        userProfile {
          id
          name
          photoUrl
        }
      }
      expectationClarityEvaluation
      teamClarityEvaluation
      companyClarityEvaluation
      goalSettingsEvaluation
      participationEvaluation
      sprintEvaluation
      sprintReviewsConducted
      activeDevSprints {
        id
        from
        to
        user {
          id
          userProfile {
            id
            name
            photoUrl
            title
          }
        }
        coach {
          id
          userProfile {
            name
            photoUrl
            title
          }
        }
        goals {
          id
          name
          completed
          description
          dueDate
          type
          isPrivate
          points
          skills {
            skill {
              id
              name
              skillCategory {
                name
              }
            }
          }
          tasks {
            id
            inserted_at
            name
            comment
            completed
            completionPercentage
            description
            feedback_url
            nps
            score
            state
            goal {
              id
              name
              type
            }
            feedback {
              task_feedback_question_id
              answer
              question
            }
          }
          companyObjective {
            id
            name
            description
            dueDate
          }
          teamObjective {
            id
            name
            description
            dueDate
            companyObjective {
              id
              name
              description
              dueDate
            }
          }
          companyObjectiveKeyResult {
            id
            completed
            title
            description
            dueDate
            evaluation
            initialValue
            targetValue
            learnings
            progress
            unit
          }
          teamObjectiveKeyResult {
            id
            completed
            title
            description
            dueDate
            evaluation
            initialValue
            targetValue
            learnings
            progress
            unit
          }
        }
      }
      visionClarityEvaluation
      wellbeingEvaluation
    }
  }
`;

const GET_COMPANY_OBJECTIVE_HISTORY = gql`
  query companyObjectiveHistory($companyObjectiveId: ID!) {
    companyObjectiveHistory(companyObjectiveId: $companyObjectiveId) {
      action
      recordedAt
      patch {
        description
        dueDate
        learnings
        name
        ownerId
        startDate
        type
      }
      actor {
        id
        userProfile {
          id
          name
          photoUrl
        }
      }
    }
  }
`;
const GET_COMPANY_OBJECTIVE_KR_HISTORY = gql`
  query companyObjectiveKeyResultHistory($companyObjectiveKeyResultId: ID!) {
    companyObjectiveKeyResultHistory(
      companyObjectiveKeyResultId: $companyObjectiveKeyResultId
    ) {
      action
      recordedAt
      notes
      patch {
        companyObjectiveId
        completed
        description
        dueDate
        initialValue
        learnings
        progress
        title
        ownerId
        targetValue
        unit
        score
      }
      actor {
        id
        userProfile {
          id
          name
          photoUrl
        }
      }
    }
  }
`;

const GET_COMPANY_USERS = gql`
  query CompanyUsers {
    companyUsers {
      id
      userId
      name
    }
  }
`;

const GET_EMPLOYEE_ROLES = gql`
  query EmployeeRoles {
    employeeRoles {
      id
      description {
        en
        fi
        se
      }
      name {
        en
        fi
        se
      }
      skills {
        expectedLevel
        skill {
          id
          name
          description
          skillCategory {
            name
            id
            key
            parentCategory {
              id
              name
              key
            }
          }
        }
      }
    }
  }
`;

const GET_EMPLOYEE_CERTIFICATES = gql`
  query certificates {
    certificates {
      authority
      company
      endMonthYear
      id
      licenseNumber
      name
      startMonthYear
      url
      userId
      userName
      userProfileId
      validUntil
    }
  }
`;

const GET_COMPANY_LIBRARY_DATA = gql`
  query companyLibraryReporting {
    companyLibraryReporting {
      careerPaths {
        name {
          en
          fi
        }
        users {
          progress
          user {
            userProfile {
              name
              photoUrl
              id
            }
          }
        }
      }
      learningPaths {
        name {
          en
          fi
        }
        users {
          id
          userProfile {
            name
            photoUrl
            id
          }
        }
      }
    }
  }
`;

const GET_COMPANY_VISIONS = gql`
  query companyVisions {
    companyVisions {
      id
      status
      vision {
        fi
        en
      }
    }
  }
`;

const CREATE_COMPANY_VISION = gql`
  mutation ($vision: VisionInput!) {
    createCompanyVision(input: $vision) {
      errors {
        key
        message
      }
      companyVision {
        id
        status
        vision {
          fi
          en
        }
      }
    }
  }
`;

const UPDATE_COMPANY_VISION = gql`
  mutation ($vision: VisionInput!, $visionId: ID!) {
    updateCompanyVision(input: $vision, visionId: $visionId) {
      errors {
        key
        message
      }
      companyVision {
        id
        status
        vision {
          fi
          en
        }
      }
    }
  }
`;

const DELETE_COMPANY_VISION = gql`
  mutation ($visionId: ID!) {
    deleteCompanyVision(visionId: $visionId) {
      errors {
        key
        message
      }
    }
  }
`;

const GET_COMPANY_STRATEGIES = gql`
  query companyStrategies {
    companyStrategies {
      id
      status
      strategy {
        fi
        en
      }
      validUntil
    }
  }
`;

const CREATE_COMPANY_STRATEGY = gql`
  mutation ($strategy: StrategyInput!) {
    createCompanyStrategy(input: $strategy) {
      errors {
        key
        message
      }
      companyStrategy {
        id
        status
        strategy {
          fi
          en
        }
        validUntil
      }
    }
  }
`;

const UPDATE_COMPANY_STRATEGY = gql`
  mutation ($strategy: StrategyInput!, $strategyId: ID!) {
    updateCompanyStrategy(input: $strategy, strategyId: $strategyId) {
      errors {
        key
        message
      }
      companyStrategy {
        id
        status
        strategy {
          fi
          en
        }
        validUntil
      }
    }
  }
`;

const DELETE_COMPANY_STRATEGY = gql`
  mutation ($strategyId: ID!) {
    deleteCompanyStrategy(strategyId: $strategyId) {
      errors {
        key
        message
      }
    }
  }
`;

const GET_COMPANY_SLACK_ANSWER_DATA = gql`
  query companySlackAnswerData {
    companySlackAnswerData {
      first {
        avg
        weeklyAvgs {
          avg
          week
          year
        }
      }
      second {
        avg
        weeklyAvgs {
          avg
          week
          year
        }
      }
      third
      fourth
    }
  }
`;

/* Actions for fetching and mutating data */

export const getCompanyObjectives = async () => {
  const res = await client.query({
    query: GET_COMPANY_OBJECTIVES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};
export const getCompanyUsersWithSkills = async () => {
  const res = await client.query({
    query: GET_COMPANY_USERS_WITH_SKILLS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createCompanyObjective = async (data) => {
  const res = await client.mutate({
    mutation: CREATE_COMPANY_OBJECTIVE,
    variables: {
      objective: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateCompanyObjective = async (data, companyObjectiveId) => {
  const res = await client.mutate({
    mutation: UPDATE_COMPANY_OBJECTIVE,
    variables: {
      companyObjectiveId: companyObjectiveId,
      objective: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const deleteCompanyObjective = async (objectiveId) => {
  const res = await client.mutate({
    mutation: DELETE_COMPANY_OBJECTIVE,
    variables: {
      companyObjectiveId: objectiveId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createCompanyObjectiveKeyResult = async (data) => {
  const res = await client.mutate({
    mutation: CREATE_COMPANY_OBJECTIVE_KEY_RESULT,
    variables: {
      input: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateCompanyObjectiveKeyResult = async (
  data,
  companyObjectiveKeyResultId
) => {
  const res = await client.mutate({
    mutation: UPDATE_COMPANY_OBJECTIVE_KEY_RESULT,
    variables: {
      companyObjectiveKeyResultId: companyObjectiveKeyResultId,
      input: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const deleteCompanyObjectiveKeyResult = async (
  companyObjectiveKeyResultId
) => {
  const res = await client.mutate({
    mutation: DELETE_COMPANY_OBJECTIVE_KEY_RESULT,
    variables: {
      companyObjectiveKeyResultId: companyObjectiveKeyResultId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getSkillsUnderDevelopment = async () => {
  const res = await client.query({
    query: GET_SKILLS_UNDER_DEVELOPMENT,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getOneOnOnes = async (year) => {
  const res = await client.query({
    query: GET_ONE_ON_ONES,
    variables: {
      year: year,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getDevelopmentSprintsData = async (from, to) => {
  const res = await client.query({
    query: GET_DEVELOPMENT_SPRINTS_DATA,
    variables: {
      from: from,
      to: to,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyObjectiveHistory = async (companyObjectiveId) => {
  const res = await client.query({
    query: GET_COMPANY_OBJECTIVE_HISTORY,
    variables: {
      companyObjectiveId: companyObjectiveId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyObjectiveKrHistory = async (companyObjectiveKRId) => {
  const res = await client.query({
    query: GET_COMPANY_OBJECTIVE_KR_HISTORY,
    variables: {
      companyObjectiveKeyResultId: companyObjectiveKRId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyUsers = async () => {
  const res = await client.query({
    query: GET_COMPANY_USERS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getEmployeeCertificates = async () => {
  const res = await client.query({
    query: GET_EMPLOYEE_CERTIFICATES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getEmployeeRoles = async () => {
  const res = await client.query({
    query: GET_EMPLOYEE_ROLES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyLibraryData = async () => {
  const res = await client.query({
    query: GET_COMPANY_LIBRARY_DATA,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyVisions = async () => {
  const res = await client.query({
    query: GET_COMPANY_VISIONS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createCompanyVision = async (companyVision) => {
  const res = await client.query({
    query: CREATE_COMPANY_VISION,
    variables: {
      vision: companyVision,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateCompanyVision = async (companyVision, id) => {
  const res = await client.query({
    query: UPDATE_COMPANY_VISION,
    variables: {
      vision: companyVision,
      visionId: id,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const deleteCompanyVision = async (id) => {
  const res = await client.query({
    query: DELETE_COMPANY_VISION,
    variables: {
      visionId: id,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyStrategies = async () => {
  const res = await client.query({
    query: GET_COMPANY_STRATEGIES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createCompanyStrategy = async (companyStrategy) => {
  const res = await client.query({
    query: CREATE_COMPANY_STRATEGY,
    variables: {
      strategy: companyStrategy,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateCompanyStrategy = async (companyStrategy, id) => {
  const res = await client.query({
    query: UPDATE_COMPANY_STRATEGY,
    variables: {
      strategy: companyStrategy,
      strategyId: id,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const deleteCompanyStrategy = async (id) => {
  const res = await client.query({
    query: DELETE_COMPANY_STRATEGY,
    variables: {
      strategyId: id,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanySlackAnswerData = async () => {
  const res = await client.query({
    query: GET_COMPANY_SLACK_ANSWER_DATA,
    fetchPolicy: "no-cache",
  });
  return res.data;
};
