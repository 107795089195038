import client from "../client";
import { gql } from "@apollo/client";

/* Goal / Milestone / Sprint related GQL Queries */

const GET_USER_GOALS = gql`
  query UserGoals($from: Date, $to: Date) {
    userGoals(from: $from, to: $to) {
      id
      name
      completed
      description
      dueDate
      type
      isPrivate
      points
      companyCareerPathId
      companyLearningPathId
      publicLearningPathId
      skills {
        skill {
          id
          name
          description
          skillCategory {
            name
          }
        }
      }
      tasks {
        id
        inserted_at
        name
        comment
        completed
        completionPercentage
        description
        feedback_url
        nps
        score
        state
        goal {
          id
          name
          type
        }
        feedback {
          task_feedback_question_id
          answer
          question
        }
      }
      companyObjective {
        id
        name
        description
        dueDate
      }
      developmentSprints {
        id
      }
      teamObjective {
        id
        name
        description
        dueDate
        companyObjective {
          id
          name
          description
          dueDate
        }
      }
      companyObjectiveKeyResult {
        id
        completed
        title
        description
        dueDate
        evaluation
        initialValue
        targetValue
        learnings
        progress
        unit
        companyObjective {
          id
          name
          skills {
            skill {
              id
              name
              description
              skillCategory {
                id
                key
                name
              }
            }
          }
        }
      }
      teamObjectiveKeyResult {
        id
        completed
        title
        description
        dueDate
        evaluation
        initialValue
        targetValue
        learnings
        progress
        unit
        teamObjective {
          id
          name
          skills {
            skill {
              id
              name
              description
              skillCategory {
                id
                key
                name
              }
            }
          }
        }
      }
    }
  }
`;

const CREATE_GOAL = gql`
  mutation ($userId: ID!, $goal: CreateGoalInput!) {
    createGoal(userId: $userId, input: $goal) {
      errors {
        key
        message
      }
      goal {
        id
        name
        description
        dueDate
        type
        isPrivate
        points
        skills {
          skill {
            id
            name
            description
            skillCategory {
              name
            }
          }
        }
        tasks {
          id
          inserted_at
          name
          comment
          completed
          completionPercentage
          description
          feedback_url
          nps
          score
          goal {
            id
            name
          }
          feedback {
            task_feedback_question_id
            answer
            question
          }
        }
        companyObjective {
          id
          name
          description
          dueDate
        }
        teamObjective {
          id
          name
          description
          dueDate
        }
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation ($userId: ID!, $goalId: ID!, $goal: UpdateGoalInput!) {
    updateGoal(userId: $userId, input: $goal, goalId: $goalId) {
      errors {
        key
        message
      }
      goal {
        id
        name
        description
        dueDate
        type
        isPrivate
        points
        skills {
          skill {
            id
            name
            description
            skillCategory {
              name
            }
          }
        }
        tasks {
          id
          inserted_at
          name
          comment
          completed
          completionPercentage
          description

          feedback_url
          nps
          score
          state
          goal {
            id
            name
          }
          feedback {
            task_feedback_question_id
            answer
            question
          }
        }
        companyObjective {
          id
          name
          description
          dueDate
        }
        teamObjective {
          id
          name
          description
          dueDate
        }
      }
    }
  }
`;

const DELETE_GOAL = gql`
  mutation ($userId: ID!, $goalId: ID!) {
    deleteGoal(userId: $userId, goalId: $goalId) {
      errors {
        key
        message
      }
      goal {
        id
      }
    }
  }
`;

const CREATE_TASK = gql`
  mutation ($userId: ID!, $goalId: ID!, $task: CreateTaskInput!) {
    createTask(userId: $userId, goalId: $goalId, input: $task) {
      errors {
        key
        message
      }
      tasks {
        id
        inserted_at
        name
        comment
        completed
        completionPercentage
        description
        feedback_url
        nps
        score
        state
        goal {
          id
          name
          type
        }
        feedback {
          task_feedback_question_id
          answer
          question
        }
      }
    }
  }
`;

const UPDATE_TASK = gql`
  mutation ($userId: ID!, $taskId: ID!, $task: UpdateTaskInput!) {
    updateTask(userId: $userId, taskId: $taskId, input: $task) {
      errors {
        key
        message
      }
      task {
        id
        completionPercentage
      }
    }
  }
`;

const DELETE_TASK = gql`
  mutation ($userId: ID!, $taskId: ID!) {
    deleteTask(userId: $userId, taskId: $taskId) {
      errors {
        key
        message
      }
      task {
        id
      }
    }
  }
`;

const UPDATE_MILESTONE_COMMENT = gql`
  mutation ($comment: String!, $taskId: ID!, $userId: ID!) {
    updateTaskComment(comment: $comment, taskId: $taskId, userId: $userId) {
      task {
        comment
      }
    }
  }
`;

const CREATE_SPRINT = gql`
  mutation ($input: DevelopmentSprintInput!) {
    createDevelopmentSprint(input: $input) {
      errors {
        key
        message
      }
      developmentSprint {
        id
      }
    }
  }
`;

const GET_SPRINTS = gql`
  query ($userId: ID!) {
    userDevelopmentSprints(userId: $userId) {
      id
      from
      to
      coach {
        id
        userProfile {
          id
          name
          photoUrl
          title
        }
      }
      hrPerson {
        id
        userProfile {
          id
          name
          photoUrl
          title
        }
      }
      peers {
        id
        userProfile {
          name
          photoUrl
        }
      }
      questions {
        id
        max
        min
        answers {
          developmentSprintReviewQuestionId
          id
          value {
            text
            integer
          }
        }
        question {
          en
          fi
          se
        }
        responder {
          id
          userProfile {
            name
          }
        }
        sliderQuestionValues {
          question {
            en
            fi
          }
          value
        }
        type
      }
      status
      checkpoints {
        id
        date
        oneOnOneHeld
        coach {
          id
          userProfile {
            id
            name
            photoUrl
            title
          }
        }
        questions {
          id
          max
          min
          question {
            en
            fi
            se
          }
          answers {
            developmentSprintCheckpointQuestionId
            id
            insertedAt
            value {
              text
              integer
            }
          }
          responder {
            id
            userProfile {
              name
            }
          }
          sliderQuestionValues {
            question {
              en
              fi
            }
            value
          }
          type
        }
      }
      goals {
        id
        name
        completed
        description
        dueDate
        type
        isPrivate
        points
        companyCareerPathId
        companyLearningPathId
        publicLearningPathId
        skills {
          skill {
            id
            name
            skillCategory {
              name
            }
          }
        }
        tasks {
          id
          inserted_at
          name
          comment
          completed
          completionPercentage
          description

          feedback_url
          nps
          score
          state
          goal {
            id
            name
            type
          }
          feedback {
            task_feedback_question_id
            answer
            question
          }
        }
        companyObjective {
          id
          name
          description
          dueDate
        }
        teamObjective {
          id
          name
          description
          dueDate
          companyObjective {
            id
            name
            description
            dueDate
          }
        }
        companyObjectiveKeyResult {
          id
          completed
          title
          description
          dueDate
          evaluation
          initialValue
          targetValue
          learnings
          progress
          unit
        }
        teamObjectiveKeyResult {
          id
          completed
          title
          description
          dueDate
          evaluation
          initialValue
          targetValue
          learnings
          progress
          unit
        }
      }
    }
  }
`;

const UPDATE_SPRINT = gql`
  mutation ($developmentSprintId: ID!, $input: DevelopmentSprintUpdateInput!) {
    updateDevelopmentSprint(
      developmentSprintId: $developmentSprintId
      input: $input
    ) {
      errors {
        key
        message
      }
    }
  }
`;

const GET_ARCHIVED_SPRINTS = gql`
  query ($userId: ID!) {
    archivedDevelopmentSprints(userId: $userId) {
      id
      coach {
        id
        userProfile {
          id
          name
          photoUrl
          title
        }
      }
      hrPerson {
        id
        userProfile {
          id
          name
          photoUrl
          title
        }
      }
      archivedData {
        id
        from
        to
        questions {
          id
          max
          min
          question {
            en
            fi
            se
          }
          answers {
            id
            value {
              text
              integer
            }
          }
          sliderQuestionValues {
            question {
              en
              fi
            }
            value
          }
          type
        }
        checkpoints {
          id
          date
          questions {
            id
            max
            min
            question {
              en
              fi
              se
            }
            answers {
              id
              value {
                text
                integer
              }
            }
            sliderQuestionValues {
              question {
                en
                fi
              }
              value
            }
            type
          }
        }
        goals {
          id
          name
          completed
          description
          dueDate
          type
          isPrivate
          points
          skills {
            skill {
              id
              name
              skillCategory {
                name
              }
            }
          }
          tasks {
            id
            inserted_at
            name
            comment
            completed
            state
            completionPercentage
            description
            feedback_url
            nps
            score
          }
          companyObjective {
            id
            name
            description
            dueDate
          }
          teamObjective {
            id
            name
            description
            dueDate
          }
          companyObjectiveKeyResult {
            id
            completed
            title
            description
            dueDate
            evaluation
            initialValue
            targetValue
            learnings
            progress
            unit
          }
          teamObjectiveKeyResult {
            id
            completed
            title
            description
            dueDate
            evaluation
            initialValue
            targetValue
            learnings
            progress
            unit
          }
        }
      }
    }
  }
`;

const GET_SELF_EVALUATION = gql`
  query selfEvaluation($developmentSprintId: ID!) {
    selfEvaluation(developmentSprintId: $developmentSprintId) {
      answered
      id
      type
      questions {
        answer {
          id
          questionId
          value {
            float
            integer
            text
          }
        }
        id
        max
        min
        order
        page
        type
        sliderQuestionValues {
          value
          question {
            en
            fi
          }
        }
        question {
          en
          fi
        }
        skill {
          name
          description
          id
        }
      }
    }
  }
`;

const CREATE_EVALUATION_ANSWERS = gql`
  mutation ($evaluationId: ID!, $input: EvaluationAnswersInput!) {
    createEvaluationAnswers(evaluationId: $evaluationId, input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

const GET_EVALUATION = gql`
  query evaluation($evaluationId: ID!) {
    evaluation(evaluationId: $evaluationId) {
      answered
      id
      type
      developmentSprint {
        id
        from
        to
        goals {
          id
          name
          completed
          description
          dueDate
          type
          skills {
            skill {
              id
              name
              description
              skillCategory {
                name
              }
            }
          }
          tasks {
            id
            inserted_at
            name
            completed
            state
            completionPercentage
            description
            dueDate
            goal {
              name
              type
            }
          }
        }
        status
        user {
          id
          userProfile {
            id
            name
            photoUrl
          }
        }
      }
      questions {
        answer {
          id
          questionId
          value {
            float
            integer
            text
          }
        }
        id
        max
        min
        order
        page
        type
        question {
          en
          fi
        }
        skill {
          name
          description
          id
        }
      }
    }
  }
`;

const CREATE_CHECKPOINT_QUESTIONS_ANSWERS = gql`
  mutation ($input: DevelopmentSprintCheckpointQuestionsAnswersInput!) {
    createDevelopmentSprintCheckpointQuestionsAnswers(input: $input) {
      developmentSprintCheckpointQuestionId
    }
  }
`;

const UPDATE_CHECKPOINT_HELD = gql`
  mutation (
    $input: DevelopmentSprintCheckpointUpdateInput!
    $developmentSprintCheckpointId: ID!
  ) {
    updateDevelopmentSprintCheckpoint(
      input: $input
      developmentSprintCheckpointId: $developmentSprintCheckpointId
    ) {
      errors {
        key
        message
      }
    }
  }
`;

const CREATE_SPRINT_REVIEW_QUESTIONS_ANSWERS = gql`
  mutation ($input: DevelopmentSprintReviewQuestionsAnswersInput!) {
    createDevelopmentSprintReviewQuestionsAnswers(input: $input) {
      developmentSprintReviewQuestionId
    }
  }
`;

const UPDATE_TASK_STATUS = gql`
  mutation ($userId: ID!, $taskId: ID!, $completed: Boolean!) {
    updateTaskStatus(userId: $userId, taskId: $taskId, completed: $completed) {
      errors {
        key
        message
      }
      task {
        id
        name
        comment
        completed
        completionPercentage
        description
        goal {
          name
        }
        feedback_url
        feedback {
          task_feedback_question_id
          answer
          question
        }
      }
    }
  }
`;

const GET_COMPANY_OBJECTIVES = gql`
  query companyObjectives {
    companyObjectives {
      description
      dueDate
      id
      name
      type
    }
  }
`;

const GET_USER_EVALUATIONS = gql`
  query ($userId: ID!) {
    userEvaluations(userId: $userId) {
      answered
      newAnswerSeen
      developmentSprint {
        id
        status
      }
      from {
        email
        id
        roles
        userProfile {
          id
          name
          photoUrl
          title
        }
      }
      id
      questions {
        answer {
          id
          questionId
          value {
            float
            integer
            text
          }
        }
        id
        max
        min
        order
        page
        question {
          en
          fi
        }
        skill {
          description
          id
          name
          skillCategory {
            id
            key
            name
          }
        }
        sliderQuestionValues {
          question {
            en
            fi
          }
          value
        }
        type
      }
      sent
      sentTo {
        email
        id
        roles
        userProfile {
          id
          name
          photoUrl
          title
        }
      }
      type
    }
  }
`;

const UPDATE_EVALUATION_SEEN = gql`
  mutation ($evaluationId: ID!) {
    updateEvaluationSeen(evaluationId: $evaluationId) {
      errors {
        key
        message
      }
      value
    }
  }
`;

const SEARCH_PUBLIC_LEARNING_PATHS_BY_SKILL = gql`
  query searchPublicLearningPathsBySkill($skillName: String!) {
    searchPublicLearningPathsBySkill(skillName: $skillName) {
      id
      name {
        en
        fi
      }
      description {
        en
        fi
      }
      type
      skills {
        skill {
          name
          id
          description
          skillCategory {
            id
            name
          }
        }
      }
      tags {
        id
        name {
          en
          fi
        }
      }
      tasks {
        id
        name {
          en
          fi
        }
        description {
          en
          fi
        }
      }
    }
  }
`;

/* Actions for fetching and mutating data */

export const getUserGoals = async (from, to) => {
  const res = await client.query({
    query: GET_USER_GOALS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getUserSprints = async (userId) => {
  const res = await client.query({
    query: GET_SPRINTS,
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getArchivedSprints = async (userId) => {
  const res = await client.query({
    query: GET_ARCHIVED_SPRINTS,
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createGoal = async (data, userId) => {
  const res = await client.mutate({
    mutation: CREATE_GOAL,
    variables: {
      userId: userId,
      goal: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateGoal = async (data, goalId, userId) => {
  const res = await client.mutate({
    mutation: UPDATE_GOAL,
    variables: {
      userId: userId,
      goalId: goalId,
      goal: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createMilestone = async (data, goalId, userId) => {
  const res = await client.mutate({
    mutation: CREATE_TASK,
    variables: {
      userId: userId,
      goalId: goalId,
      task: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createMilestoneComment = async (comment, milestoneId, userId) => {
  const res = await client.mutate({
    mutation: UPDATE_MILESTONE_COMMENT,
    variables: {
      comment: comment,
      taskId: milestoneId,
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const editMilestone = async (data, milestoneId, userId) => {
  const res = await client.mutate({
    mutation: UPDATE_TASK,
    variables: {
      task: data,
      taskId: milestoneId,
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const deleteGoal = async (goalId, userId) => {
  const res = await client.mutate({
    mutation: DELETE_GOAL,
    variables: {
      userId: userId,
      goalId: goalId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateMilestoneStatus = async (taskId, userId, status) => {
  const res = await client.mutate({
    mutation: UPDATE_TASK_STATUS,
    variables: {
      userId: userId,
      taskId: taskId,
      completed: status,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateMilestoneCompletionPercentage = async (
  taskId,
  userId,
  percentage
) => {
  const res = await client.mutate({
    mutation: UPDATE_TASK_STATUS,
    variables: {
      userId: userId,
      taskId: taskId,
      milestoneCompletion: percentage,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateSprintStatus = async (sprintId, updateData) => {
  const res = await client.mutate({
    mutation: UPDATE_SPRINT,
    variables: {
      developmentSprintId: sprintId,
      input: updateData,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const deleteMilestone = async (taskId, userId) => {
  const res = await client.mutate({
    mutation: DELETE_TASK,
    variables: {
      userId: userId,
      taskId: taskId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createSprint = async (data) => {
  const res = await client.mutate({
    mutation: CREATE_SPRINT,
    variables: {
      input: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createCheckpointQuestionsAnswers = async (data) => {
  const res = await client.mutate({
    mutation: CREATE_CHECKPOINT_QUESTIONS_ANSWERS,
    variables: {
      input: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateCheckpointHeld = async (data, id) => {
  const res = await client.mutate({
    mutation: UPDATE_CHECKPOINT_HELD,
    variables: {
      input: data,
      developmentSprintCheckpointId: id,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createSprintReviewQuestionsAnswers = async (data) => {
  const res = await client.mutate({
    mutation: CREATE_SPRINT_REVIEW_QUESTIONS_ANSWERS,
    variables: {
      input: data,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyObjectives = async () => {
  const res = await client.query({
    query: GET_COMPANY_OBJECTIVES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getSelfEvaluation = async (sprintId) => {
  const res = await client.query({
    query: GET_SELF_EVALUATION,
    errorPolicy: "all",
    variables: {
      developmentSprintId: sprintId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createEvaluationAnswers = async (data, evaluationId) => {
  const res = await client.mutate({
    mutation: CREATE_EVALUATION_ANSWERS,
    variables: {
      input: { answers: data },
      evaluationId: evaluationId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getEvaluation = async (evaluationId) => {
  const res = await client.query({
    query: GET_EVALUATION,
    errorPolicy: "all",
    variables: {
      evaluationId: evaluationId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getUserEvaluations = async (userId) => {
  const res = await client.query({
    query: GET_USER_EVALUATIONS,
    errorPolicy: "all",
    variables: {
      userId: userId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const updateEvaluationSeen = async (evaluationId) => {
  const res = await client.query({
    query: UPDATE_EVALUATION_SEEN,
    errorPolicy: "all",
    variables: {
      evaluationId: evaluationId,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const searchPublicLearningPathsBySkill = async (skillName) => {
  const res = await client.query({
    query: SEARCH_PUBLIC_LEARNING_PATHS_BY_SKILL,
    errorPolicy: "all",
    variables: {
      skillName,
    },
    fetchPolicy: "no-cache",
  });
  return res.data;
};
