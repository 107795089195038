import client from "../client";
import { gql } from "@apollo/client";

/* Admin related GQL Queries and Mutations */

const GET_COMPANY_SETTINGS = gql`
  query CompanySettings {
    companyHasSlackIntegration
    stripeInfo {
      customerId
      subscriptionStatus
      trialDaysLeft
    }
    upcomingStripeInvoice {
      currency
      total
    }
    stripeCustomerPortalUrl
  }
`;

const GET_COMPANY_USERS = gql`
  query CompanyUsers {
    companyUsers {
      id
      userId
      photoUrl
      name
      roles
    }
  }
`;

const GET_COMPANY_TAGS = gql`
  query companyLearningPathTags {
    companyLearningPathTags {
      id
      name {
        en
        fi
      }
      description {
        en
        fi
      }
    }
  }
`;

const CREATE_COMPANY_TAG = gql`
  mutation ($input: CompanyLearningPathTagInput!) {
    createCompanyLearningPathTag(input: $input) {
      errors {
        key
        message
      }
      companyLearningPathTag {
        id
      }
    }
  }
`;

const UPDATE_COMPANY_TAG = gql`
  mutation (
    $input: CompanyLearningPathTagInput!
    $companyLearningPathTagId: ID!
  ) {
    updateCompanyLearningPathTag(
      input: $input
      companyLearningPathTagId: $companyLearningPathTagId
    ) {
      errors {
        key
        message
      }
      companyLearningPathTag {
        id
      }
    }
  }
`;

const DELETE_COMPANY_TAG = gql`
  mutation ($companyLearningPathTagId: ID!) {
    deleteCompanyLearningPathTag(
      companyLearningPathTagId: $companyLearningPathTagId
    ) {
      errors {
        key
        message
      }
    }
  }
`;

const GET_COMPANY_CAREER_PATHS = gql`
  query companyCareerPaths {
    companyCareerPaths {
      id
      pathOrder
      name {
        en
        fi
      }
      description {
        en
        fi
      }
      companyLearningPaths {
        id
        imageUrl
        type
        name {
          en
          fi
        }
        description {
          en
          fi
        }
        points
        taskInterval
        skills {
          skill {
            id
            name
            skillCategory {
              id
              key
              name
            }
          }
        }
        tasks {
          id
          description {
            en
            fi
          }
          name {
            en
            fi
          }
        }
        tags {
          description {
            en
            fi
          }
          id
          name {
            en
            fi
          }
        }
      }
    }
  }
`;

const DELETE_COMPANY_LEARNING_PATH = gql`
  mutation ($companyLearningPathId: ID!) {
    deleteCompanyLearningPath(companyLearningPathId: $companyLearningPathId) {
      errors {
        key
        message
      }
    }
  }
`;

const DELETE_COMPANY_CAREER_PATH = gql`
  mutation ($companyCareerPathId: ID!) {
    deleteCompanyCareerPath(companyCareerPathId: $companyCareerPathId) {
      errors {
        key
        message
      }
    }
  }
`;

const GET_COMPANY_LEARNING_PATHS = gql`
  query companyLearningPaths {
    companyLearningPaths {
      id
      imageUrl
      type
      name {
        en
        fi
      }
      description {
        en
        fi
      }
      points
      taskInterval
      skills {
        skill {
          id
          name
          skillCategory {
            id
            key
            name
          }
        }
      }
      tasks {
        id
        description {
          en
          fi
        }
        name {
          en
          fi
        }
      }
      tags {
        description {
          en
          fi
        }
        id
        name {
          en
          fi
        }
      }
    }
  }
`;

const CREATE_COMPANY_CAREER_PATH = gql`
  mutation ($input: CompanyCareerPathInput!) {
    createCompanyCareerPath(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

const UPDATE_COMPANY_CAREER_PATH = gql`
  mutation ($input: CompanyCareerPathInput!, $companyCareerPathId: ID!) {
    updateCompanyCareerPath(
      input: $input
      companyCareerPathId: $companyCareerPathId
    ) {
      errors {
        key
        message
      }
      companyCareerPath {
        id
        name {
          en
          fi
        }
        description {
          en
          fi
        }
      }
    }
  }
`;

const CREATE_COMPANY_LEARNING_PATH = gql`
  mutation ($input: CompanyLearningPathInput!) {
    createCompanyLearningPath(input: $input) {
      errors {
        key
        message
      }
      companyLearningPath {
        id
        imageUrl
        type
        name {
          en
          fi
        }
        description {
          en
          fi
        }
        points
        taskInterval
        tasks {
          id
          description {
            en
            fi
          }
          name {
            en
            fi
          }
        }
        tags {
          description {
            en
            fi
          }
          id
          name {
            en
            fi
          }
        }
      }
    }
  }
`;

const UPDATE_COMPANY_LEARNING_PATH = gql`
  mutation ($input: CompanyLearningPathInput!, $companyLearningPathId: ID!) {
    updateCompanyLearningPath(
      input: $input
      companyLearningPathId: $companyLearningPathId
    ) {
      errors {
        key
        message
      }
      companyLearningPath {
        id
        imageUrl
        type
        name {
          en
          fi
        }
        description {
          en
          fi
        }
        points
        taskInterval
        tasks {
          description {
            en
            fi
          }
          name {
            en
            fi
          }
        }
        tags {
          id
          description {
            en
            fi
          }
          id
          name {
            en
            fi
          }
        }
      }
    }
  }
`;

const REGISTER_USER = gql`
  mutation ($user: CompanyAdminRegisterUserInput!) {
    companyAdminRegisterUser(input: $user) {
      errors {
        key
        message
      }
      user {
        id
        email
      }
    }
  }
`;

const REGISTER_TEAM_USER = gql`
  mutation ($user: CompanyAdminRegisterUserInput!, $teamId: ID!) {
    teamLeadRegisterUser(input: $user, teamId: $teamId) {
      errors {
        key
        message
      }
      user {
        id
      }
    }
  }
`;

const RESEND_MAIL = gql`
  mutation ($email: String!) {
    resendSignUpMail(email: $email) {
      errors
      success
    }
  }
`;

const GET_PENDING_INVITES = gql`
  query UserInvites {
    userInvites {
      email
    }
  }
`;

const GET_COMPANY_TEAMS = gql`
  query Teams {
    teams {
      id
      name
      parentTeam {
        id
        name
      }
      teamLead {
        id
        email
        userProfile {
          id
          name
          email
          photoUrl
        }
      }
      members {
        id
        email
        userProfile {
          id
          name
          photoUrl
        }
      }
    }
  }
`;

const CREATE_EMPLOYEE_ROLE = gql`
  mutation ($input: EmployeeRoleInput!) {
    createEmployeeRole(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

const UPDATE_EMPLOYEE_ROLE = gql`
  mutation ($input: EmployeeRoleInput!, $employeeRoleId: ID!) {
    updateEmployeeRole(input: $input, employeeRoleId: $employeeRoleId) {
      errors {
        key
        message
      }
    }
  }
`;

const DELETE_EMPLOYEE_ROLE = gql`
  mutation ($employeeRoleId: ID!) {
    deleteEmployeeRole(employeeRoleId: $employeeRoleId) {
      errors {
        key
        message
      }
    }
  }
`;

const GET_COMPANY_SKILLS = gql`
  query GetCompanySkills {
    getCompanySkills {
      id
      name
      description
      skillCategory {
        name
        key
        id
        parentCategory {
          id
          name
          key
        }
      }
    }
  }
`;

const GET_SKILL_CATEGORIES = gql`
  query SkillCategories {
    skillCategories {
      name
      id
      parentCategory {
        id
        name
      }
    }
  }
`;

const GET_COMPANY_FUTURE_SKILLS = gql`
  query GetCompanyFutureSkills {
    companyFutureSkills {
      id
      endDate
      numberOfEmployees
      requiredLevel
      skill {
        id
        name
        description
        skillCategory {
          name
          key
          id
          parentCategory {
            id
            name
            key
          }
        }
      }
    }
  }
`;

/* Admin actions for fetching and mutating data */

export const getCompanySettings = async () => {
  const res = await client.query({
    query: GET_COMPANY_SETTINGS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyUsers = async () => {
  const res = await client.query({
    query: GET_COMPANY_USERS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyTags = async () => {
  const res = await client.query({
    query: GET_COMPANY_TAGS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createCompanyTag = async (input) => {
  const res = await client.mutate({
    mutation: CREATE_COMPANY_TAG,
    fetchPolicy: "no-cache",
    variables: {
      input: input,
    },
  });
  return res.data;
};

export const updateCompanyTag = async (input, tagId) => {
  const res = await client.mutate({
    mutation: UPDATE_COMPANY_TAG,
    fetchPolicy: "no-cache",
    variables: {
      input: input,
      companyLearningPathTagId: tagId,
    },
  });
  return res.data;
};

export const deleteCompanyTag = async (tagId) => {
  const res = await client.mutate({
    mutation: DELETE_COMPANY_TAG,
    fetchPolicy: "no-cache",
    variables: {
      companyLearningPathTagId: tagId,
    },
  });
  return res.data;
};

export const getCompanyCareerPaths = async () => {
  const res = await client.query({
    query: GET_COMPANY_CAREER_PATHS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyLearningPaths = async () => {
  const res = await client.query({
    query: GET_COMPANY_LEARNING_PATHS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const createCompanyCareerPath = async (input) => {
  const res = await client.mutate({
    mutation: CREATE_COMPANY_CAREER_PATH,
    fetchPolicy: "no-cache",
    variables: {
      input: input,
    },
  });
  return res.data;
};

export const updateCompanyCareerPath = async (input, pathId) => {
  const res = await client.mutate({
    mutation: UPDATE_COMPANY_CAREER_PATH,
    fetchPolicy: "no-cache",
    variables: {
      input: input,
      companyCareerPathId: pathId,
    },
  });
  return res.data;
};

export const deleteCompanyCareerPath = async (pathId) => {
  const res = await client.mutate({
    mutation: DELETE_COMPANY_CAREER_PATH,
    fetchPolicy: "no-cache",
    variables: {
      companyCareerPathId: pathId,
    },
  });
  return res.data;
};

export const createCompanyLearningPath = async (input) => {
  const res = await client.mutate({
    mutation: CREATE_COMPANY_LEARNING_PATH,
    fetchPolicy: "no-cache",
    variables: {
      input: input,
    },
  });
  return res.data;
};

export const updateCompanyLearningPath = async (input, pathId) => {
  const res = await client.mutate({
    mutation: UPDATE_COMPANY_LEARNING_PATH,
    fetchPolicy: "no-cache",
    variables: {
      input: input,
      companyLearningPathId: pathId,
    },
  });
  return res.data;
};

export const deleteCompanyLearningPath = async (goalId) => {
  const res = await client.mutate({
    mutation: DELETE_COMPANY_LEARNING_PATH,
    fetchPolicy: "no-cache",
    variables: {
      companyLearningPathId: goalId,
    },
  });
  return res.data;
};

export const getCompanyTeams = async () => {
  const res = await client.query({
    query: GET_COMPANY_TEAMS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getPendingInvites = async () => {
  const res = await client.query({
    query: GET_PENDING_INVITES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const sendInvite = async (email) => {
  const res = await client.mutate({
    mutation: REGISTER_USER,
    fetchPolicy: "no-cache",
    variables: {
      user: {
        email: email,
      },
    },
  });
  return res.data;
};

export const sendTeamInvite = async (email, teamId) => {
  const res = await client.mutate({
    mutation: REGISTER_TEAM_USER,
    fetchPolicy: "no-cache",
    variables: {
      user: {
        email: email,
      },
      teamId: teamId,
    },
  });
  return res.data;
};

export const resendInvite = async (email) => {
  const res = await client.mutate({
    mutation: RESEND_MAIL,
    fetchPolicy: "no-cache",
    variables: {
      email: email,
    },
  });
  return res.data;
};

export const createEmployeeRole = async (input) => {
  const res = await client.mutate({
    mutation: CREATE_EMPLOYEE_ROLE,
    fetchPolicy: "no-cache",
    variables: {
      input: input,
    },
  });
  return res.data;
};

export const updateEmployeeRole = async (input, employeeRoleId) => {
  const res = await client.mutate({
    mutation: UPDATE_EMPLOYEE_ROLE,
    fetchPolicy: "no-cache",
    variables: {
      input: input,
      employeeRoleId: employeeRoleId,
    },
  });
  return res.data;
};

export const deleteEmployeeRole = async (employeeRoleId) => {
  const res = await client.mutate({
    mutation: DELETE_EMPLOYEE_ROLE,
    fetchPolicy: "no-cache",
    variables: {
      employeeRoleId: employeeRoleId,
    },
  });
  return res.data;
};

export const getCompanySkills = async () => {
  const res = await client.query({
    query: GET_COMPANY_SKILLS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getSkillCategories = async () => {
  const res = await client.query({
    query: GET_SKILL_CATEGORIES,
    fetchPolicy: "no-cache",
  });
  return res.data;
};

export const getCompanyFutureSkills = async () => {
  const res = await client.query({
    query: GET_COMPANY_FUTURE_SKILLS,
    fetchPolicy: "no-cache",
  });
  return res.data;
};
